import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeacherService } from 'src/app/project/services/teacher.service';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-business-class',
  templateUrl: './logsBusiness.component.html',
  styleUrls: ['./logsBusiness.component.scss']
})
export class LogsBusinessComponent implements OnInit {

  modalRef: BsModalRef;
  listAllTeams = [];
  idTeam = 0;
  idArea = 0;

  // DELIVERY

  priceHours = 0.5;
  priceEquip_1 = 151;
  priceEquip_2 = 191;
  priceEquip_3 = 252;
  priceInstagramDelivery = 30;
  priceFlyerDelivery = 10;

  priceNorth = 340;
  priceCenter = 400;
  priceSouth = 320;

  // MANUFACTURE

  pricePackingManufacture = 10.30;
  priceDeliveryManufacture = 0.40;
  priceAssistant = 800;
  priceTwoAssistant = 800;
  priceThreeAssistant = 800;

  // PIZZA

  priceFlour = 0.30;
  priceOil = 0.24;
  priceSalt = 0.032;
  priceYeast = 0.40;
  priceTomato = 0.12;
  priceMozzarella = 0.96;

  pricePacking = 29.5;
  priceDelivery = 2.00;

  priceChef = 1700;
  priceAssistantPizza = 800;
  priceTwoAssistantPizza = 800;
  priceTasting = 1000;

  valuePizza: any;
  valueManufacture: any;
  valueDelivery: any;

  // Variables componente cargando  

  loading = false;

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private teacherService: TeacherService,
    private ux: UxService,
    private teamService: TeamService,
    private modalService: BsModalService,
  ) {

    this.getAllTeams();

  }

  ngOnInit() {

  }

  getAllTeams() {

    this.showCargando();

    let params = {
      user: localStorage.getItem('id'),
    }

    this.teacherService.getAllTeams(params)

      .subscribe(data => {
        if (data.status == "OK") {

          this.listAllTeams = data.data;

          this.listAllTeams.forEach(element => {
            let dir = element.url.split("/")

            if (dir[3] == "Restart") {
              element.changeMarket = true;
            } else {
              element.changeMarket = false;
            }
          });

          this.hideCargando();

        } else {
          this.ux.notificacion('Error in get all teams.', 'danger');
          this.hideCargando();
        }
      });
  }

  getIngredient(template) {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getIngredientPizza(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          this.valuePizza = data.data;

          if (this.valuePizza != null) {

            this.valuePizza.ingredient_total = this.valuePizza.flour + this.valuePizza.oil + this.valuePizza.spices +
              this.valuePizza.yeast + this.valuePizza.tomato + this.valuePizza.chesse;

            this.valuePizza.packing_total = this.valuePizza.packaging * this.pricePacking;
            this.valuePizza.delivery_total = this.valuePizza.delivery * this.priceDelivery;
            this.valuePizza.packing_delivery_total = this.valuePizza.packing_total + this.valuePizza.delivery_total;

            this.valuePizza.chef_total = this.valuePizza.chef * this.priceChef;
            this.valuePizza.first_assistant_total = this.valuePizza.first_assistant * this.priceAssistantPizza;
            this.valuePizza.second_assistant_total = this.valuePizza.second_assistant * this.priceTwoAssistantPizza;
            this.valuePizza.assistant_total = this.valuePizza.chef_total + this.valuePizza.first_assistant_total + this.valuePizza.second_assistant_total;

            switch (this.valuePizza.location) {
              case "north":
                this.valuePizza.locationPrice = this.priceNorth;
                break;

              case "center":
                this.valuePizza.locationPrice = this.priceCenter;
                break;

              case "south":
                this.valuePizza.locationPrice = this.priceSouth;
                break;

              default:
                this.valuePizza.locationPrice = 0;
                break;
            }

            this.valuePizza.promotion_instagram_total = this.valuePizza.promotion_instagram * this.priceInstagramDelivery;
            this.valuePizza.promotion_flyers_total = this.valuePizza.promotion_flyers * this.priceFlyerDelivery;
            this.valuePizza.promotion_tasting_total = this.valuePizza.promotion_tasting * this.priceTasting;

            this.valuePizza.promotion_total = this.valuePizza.promotion_instagram_total + this.valuePizza.promotion_flyers_total + this.valuePizza.promotion_tasting_total + this.valuePizza.locationPrice;

          }

          this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });

        }
      });
  }

  getValuesManufacture(template) {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesManufacture(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          this.valueManufacture = data.data;

          if (this.valueManufacture != null) {
            this.valueManufacture.packaging_total = this.valueManufacture.packaging * this.pricePackingManufacture;
            this.valueManufacture.delivery_total = this.valueManufacture.delivery * this.priceDeliveryManufacture;

            this.valueManufacture.first_delivery_total = this.valueManufacture.first_delivery * this.priceAssistant;
            this.valueManufacture.second_delivery_total = this.valueManufacture.second_delivery * this.priceTwoAssistant;
            this.valueManufacture.third_delivery_total = this.valueManufacture.third_delivery * this.priceThreeAssistant;
            this.valueManufacture.delivery_total_assistant = this.valueManufacture.first_delivery_total + this.valueManufacture.second_delivery_total + this.valueManufacture.third_delivery_total + this.valueManufacture.packaging_total + this.valueManufacture.delivery_total

            this.valueManufacture.promotion_instagram_total = this.valueManufacture.promotion_instagram * this.priceInstagramDelivery;
            this.valueManufacture.promotion_flyers_total = this.valueManufacture.promotion_flyers * this.priceFlyerDelivery;
            this.valueManufacture.promotion_total = this.valueManufacture.promotion_instagram_total + this.valueManufacture.promotion_flyers_total;

            switch (this.valueManufacture.location) {
              case "north":
                this.valueManufacture.locationPrice = this.priceNorth;
                break;

              case "center":
                this.valueManufacture.locationPrice = this.priceCenter;
                break;

              case "south":
                this.valueManufacture.locationPrice = this.priceSouth;
                break;

              default:
                this.valueManufacture.locationPrice = 0;
                break;
            }

          }

          this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
        }
      });

  }

  getValuesDelivery(template) {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesDelivery(param)

      .subscribe(data => {
        if (data.status == "OK") {

          this.hideCargando();

          this.valueDelivery = data.data;

          if (this.valueDelivery != null) {
            this.valueDelivery.first_delivery_total = this.valueDelivery.first_delivery * this.priceHours;
            this.valueDelivery.second_delivery_total = this.valueDelivery.second_delivery * this.priceHours;
            this.valueDelivery.third_delivery_total = this.valueDelivery.third_delivery * this.priceHours;
            this.valueDelivery.delivery_total = this.valueDelivery.first_delivery_total + this.valueDelivery.second_delivery_total + this.valueDelivery.third_delivery_total

            this.valueDelivery.standard_equipment_total = this.valueDelivery.standard_equipment * this.priceEquip_1;
            this.valueDelivery.city_equipment_total = this.valueDelivery.city_equipment * this.priceEquip_2;
            this.valueDelivery.professional_equipment_total = this.valueDelivery.professional_equipment * this.priceEquip_3;
            this.valueDelivery.equipment_total = this.valueDelivery.standard_equipment_total + this.valueDelivery.city_equipment_total + this.valueDelivery.professional_equipment

            this.valueDelivery.promotion_instagram_total = this.valueDelivery.promotion_instagram * this.priceInstagramDelivery;
            this.valueDelivery.promotion_flyers_total = this.valueDelivery.promotion_flyers * this.priceFlyerDelivery;
            this.valueDelivery.promotion_total = this.valueDelivery.promotion_instagram_total + this.valueDelivery.promotion_flyers_total;

          }

          this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });

        }
      });

  }

  changeMarket(option) {

    this.showCargando();

    let param = {
      idTeam: this.idTeam,
      option: this.getOption(option),
      idArea: this.idArea
    }

    this.teacherService.changeMarket(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
          this.closeModal();
          this.getAllTeams();
        }
      });

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  getOption(opt) {

    let result = "";

    switch (opt) {
      case "opc1":
        result = "Consumers have decreased"
        break;

      case "opc2":
        result = "More competitors have arrived"
        break;

      case "opc3":
        result = "The market conditions have not changed"
        break;
    }

    return result;
  }

  showModal(template, data) {

    this.idTeam = data.id;

    this.idArea = data.idArea;

    switch (data.idArea) {
      case 1:
        this.getIngredient(template);
        break;

      case 2:
        this.getValuesManufacture(template);
        break;

      case 3:
        this.getValuesDelivery(template);
        break;
    }

  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }



}
