import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SelectItem } from 'primeng/components/common/selectitem';
import { TeamService } from 'src/app/project/services/team.service';
import { log } from 'util';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.scss']
})
export class CabeceraComponent implements OnInit {

  modalRef: BsModalRef;
  dropdownSession: SelectItem[];
  tipoUsuario: string;
  isCelular: boolean = false;
  idGame: any;
  nameTeam: any;
  showMarket: any;
  idArea: any;
  idTeam: any;
  currentModalIndex = 1;
  config = {};
  session = "low";
  urlPercentage = "assets/images/seasonal-turnout-50.png";
  marketCovered = 50;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private teamService: TeamService,
  ) {

    this.tipoUsuario = localStorage.getItem('user');
    this.idGame = localStorage.getItem('userName');
    this.nameTeam = localStorage.getItem('nameTeam');
    this.idArea = localStorage.getItem('idArea');
    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.isCelular = true;
    }

    this.dropdownSession = [
      { label: 'High', value: 'high' },
      { label: 'Mid', value: 'mid' },
      { label: 'Low', value: 'low' },
    ];

    this.showMarket = localStorage.getItem('showMarket');

    if (this.tipoUsuario != "teacher") {
      this.getCanvas();
    }
  }

  ngOnInit() {
  }

  getCanvas() {

    let params = {
      idTeam: this.idTeam,
    }

    this.teamService.getCanvas(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.status == "OK") {

          if (dataCanvas.data != null) {

            this.idArea = dataCanvas.data.idAreaSelect.toString();
            this.showMarket = true;

          }
        }

      });

  }

  agoraMarket(templatePizza, templateManufacture, templateDelivery) {

    switch (this.idArea) {
      case "1":

        this.config = {
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 13,
        }

        this.modalRef = this.modalService.show(templatePizza, { class: 'modal-xl', ignoreBackdropClick: true });
        break;

      case "2":

        this.config = {
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 29.50,
        }

        this.modalRef = this.modalService.show(templateManufacture, { class: 'modal-xl', ignoreBackdropClick: true });
        break;

      case "3":

        this.config = {
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 2,
        }

        this.modalRef = this.modalService.show(templateDelivery, { class: 'modal-xl', ignoreBackdropClick: true });
        break;


    }

  }

  closeSession() {

    var classFondo = document.getElementsByClassName("fondoQuiz")[0];

    if (classFondo != null) {

      if (classFondo.className = "fondoQuiz") {
        classFondo.className = "fondo";
      }
    }

    localStorage.clear();

    if (this.tipoUsuario == 'teacher') {
      this.router.navigateByUrl("/login/teacher");
    } else if (this.tipoUsuario == 'team') {
      this.router.navigateByUrl("/login/team");
    }

  }

  changeModalIndex(tipo, modal) {

    if (modal == 'pizza') {

      if (tipo == "+" && this.currentModalIndex < 4) {
        this.currentModalIndex++;
      } else if (tipo == "-" && this.currentModalIndex > 1) {
        this.currentModalIndex--;
      }

    } else if (modal == 'manufacture') {

      if (tipo == "+" && this.currentModalIndex < 4) {
        this.currentModalIndex++;
      } else if (tipo == "-" && this.currentModalIndex > 1) {
        this.currentModalIndex--;
      }

    } else if (modal == 'delivery') {

      if (tipo == "+" && this.currentModalIndex < 4) {
        this.currentModalIndex++;
      } else if (tipo == "-" && this.currentModalIndex > 1) {
        this.currentModalIndex--;
      }

    }



  }

  onChangeDropdown($event) {

    this.session = $event.value;

    switch (this.session) {
      case "high":
        this.urlPercentage = "assets/images/seasonal-turnout-100.png"
        this.marketCovered = 100;
        break;

      case "mid":
        this.urlPercentage = "assets/images/seasonal-turnout-75.png"
        this.marketCovered = 75;
        break;

      case "low":
        this.urlPercentage = "assets/images/seasonal-turnout-50.png"
        this.marketCovered = 50;
        break;


    }


  }


  closeModal() {
    this.modalRef.hide();
  }
}
