import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-packing-pizza',
  templateUrl: './packingPizza.component.html',
  styleUrls: ['./packingPizza.component.scss']
})
export class PackingPizzaComponent implements OnInit {

  modalRef: BsModalRef;
  cantPacking = 0;
  cantDelivery = 0;
  sellPizza = 0;

  valuePacking: number = 0;
  valueDelivery: number = 0;
  valueTotal: number = 0;

  pricePacking = 0;
  priceDelivery = 0;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.pricePacking = 29.5;
    this.priceDelivery = 2.00;

    this.getIngredient();

  }

  ngOnInit() {
  }

  getIngredient() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getIngredientPizza(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          this.cantPacking = data.data.packaging
          this.cantDelivery = data.data.delivery
          this.sellPizza = data.data.sellPizza

          this.valuePacking = parseFloat((this.cantPacking * this.pricePacking).toFixed(2));
          this.valueDelivery = parseFloat((this.cantDelivery * this.priceDelivery).toFixed(2));

          this.valueTotal = parseFloat((this.valueDelivery + this.valuePacking).toFixed(2));;

        }
      });

  }

  buyPacking() {

    this.showCargando();

    try {

      if (this.valueTotal > 0) {

        let valueCostPacking = parseFloat(this.valueTotal.toString()).toFixed(2);
        let valueCostIngre = parseFloat(localStorage.getItem('valueTotalIngredient').toString()).toFixed(2);

        let valueTotal = parseFloat(valueCostPacking) + parseFloat(valueCostIngre);

        let param = {
          valueTotal: valueTotal,
          packing: this.cantPacking,
          delivery: this.cantDelivery,
          idTeam: this.idTeam
        }

        this.teamService.updateCostVar(param)
          .subscribe(data => {
            if (data.status == "OK") {
              this.hideCargando();
              localStorage.setItem('valueTotalPacking', this.valueTotal.toString());
              this.goTo("attendee");
            } else {
              this.hideCargando();
              this.ux.notificacion("Error, favor intentar nuevamente", 'error');
            }
          });

      } else {
        this.hideCargando();
        this.ux.notificacion('You must buy at least one delivery type.', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  goTo(page) {

    switch (page) {

      case 'ingredient':
        this.router.navigateByUrl('/team/game/pizza/costVariable');
        break;

      case 'packing':
        this.router.navigateByUrl('/team/game/pizza/packing');
        break;

      case 'attendee':
        this.router.navigateByUrl('/team/game/pizza/attendee');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/pizza/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/pizza/location');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updatePacking(tipo, ingredient) {

    if (tipo == "add") {

      switch (ingredient) {
        case "delivery":
          this.cantDelivery++;
          this.valueDelivery = parseFloat((this.cantDelivery * this.priceDelivery).toFixed(2));
          break;
        case "packing":
          this.cantPacking++;
          this.valuePacking = parseFloat((this.cantPacking * this.pricePacking).toFixed(2));
          break;
      }

    } else if (tipo == "rest") {

      switch (ingredient) {
        case "delivery":
          if (this.cantDelivery >= 1) {
            this.cantDelivery--;
            this.valueDelivery = parseFloat((this.cantDelivery * this.priceDelivery).toFixed(2));
          }
          break;
        case "packing":
          if (this.cantPacking >= 1) {
            this.cantPacking--;
            this.valuePacking = parseFloat((this.cantPacking * this.pricePacking).toFixed(2));
          }
          break;
      }

    }

    this.valueTotal = parseFloat((this.valueDelivery + this.valuePacking).toFixed(2));;

  }

  updatePackingChange($event, tipo) {

    switch (tipo) {
      case "delivery":
        this.valueDelivery = parseFloat((this.cantDelivery * this.priceDelivery).toFixed(2));
        break;
      case "packing":
        this.valuePacking = parseFloat((this.cantPacking * this.pricePacking).toFixed(2));
        break;
    }

    this.valueTotal = parseFloat((this.valueDelivery + this.valuePacking).toFixed(2));;

  }

  showModalInfo(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
