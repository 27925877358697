import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../../project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { GameAreaSetting } from '../../../../assets/utils/GameAreaUtil';
import { TeamService } from 'src/app/project/services/team.service';
import { TeacherService } from 'src/app/project/services/teacher.service';
import { SelectItem } from 'primeng/components/common/selectitem';


@Component({
  selector: 'app-canvas-grade',
  templateUrl: './gradeCanvas.component.html',
  styleUrls: ['./gradeCanvas.component.scss']
})
export class GradeCanvasComponent implements OnInit {

  modalRef: BsModalRef;
  config = {};
  disabledBtn = true;
  dataModal;

  key_partners = "";
  key_activities = "";
  key_resources = "";
  cost_structure = "";
  channels = "";
  value_propositions = "";
  customer_relationship = "";
  customer_segments = "";
  revenue_streams = "";

  key_partners_value = 0;
  key_activities_value = 0;
  key_resources_value = 0;
  cost_structure_value = 0;
  channels_value = 0;
  value_propositions_value = 0;
  customer_relationship_value = 0;
  customer_segments_value = 0;
  revenue_streams_value = 0;

  key_partners_visit = false;
  key_activities_visit = false;
  key_resources_visit = false;
  cost_structure_visit = false;
  channels_visit = false;
  value_propositions_visit = false;
  customer_relationship_visit = false;
  customer_segments_visit = false;
  revenue_streams_visit = false;

  btnDisabled = true;

  data = "";
  selectCanvas = "";
  idTeam = 0;
  idTime: any;

  listCanvas: any = [];
  dropdownGrade: SelectItem[];

  totalGrade = 0;
  totalFinancing = 0;
  totalFinancingString = "SF";
  idAreaSelect = 0;

  // TODO:Cambiar 
  seasonSelect = "LOW";

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ux: UxService,
    private teacherService: TeacherService,
    private teamService: TeamService,
    private modalService: BsModalService,

  ) {

    var classFondo = document.getElementsByClassName("fondoQuiz")[0];

    if (classFondo != null) {

      if (classFondo.className = "fondoQuiz") {
        classFondo.className = "fondo";
      }
    }

    this.idTeam = this.teacherService.getIdTeam();

    if (this.idTeam == null) {
      this.idTeam = parseInt(localStorage.getItem('idTeamSelect'));
    }

    this.dropdownGrade = [
      { label: 'VERY GOOD', value: 3 },
      { label: 'GOOD', value: 2 },
      { label: 'NOT GOOD', value: 1 },
    ];

    this.getCanvas();

  }

  ngOnInit() { }

  getCanvas() {

    this.showCargando();

    let params = {
      idTeam: this.idTeam,
    }

    this.teamService.getCanvas(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.status == "OK") {

          this.hideCargando();

          if (dataCanvas.data != null) {

            this.key_partners = dataCanvas.data.key_partners;
            this.key_activities = dataCanvas.data.key_activities;
            this.key_resources = dataCanvas.data.key_resources;
            this.cost_structure = dataCanvas.data.cost_structure;
            this.channels = dataCanvas.data.channels;
            this.value_propositions = dataCanvas.data.value_propositions;
            this.customer_relationship = dataCanvas.data.customer_relationship;
            this.customer_segments = dataCanvas.data.customer_segments;
            this.revenue_streams = dataCanvas.data.revenue_streams;
            this.idAreaSelect = dataCanvas.data.idAreaSelect;

            this.listCanvas.push({ icon: "assets/images/ico-key-partners.png", item: "Key Partners", resp: dataCanvas.data.key_partners, showBtn: (dataCanvas.data.key_partners.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-key-activities.png", item: "Key Activities", resp: dataCanvas.data.key_activities, showBtn: (dataCanvas.data.key_activities.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-key-resources.png", item: "Key Resources", resp: dataCanvas.data.key_resources, showBtn: (dataCanvas.data.key_resources.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-cost-structure.png", item: "Cost Structure", resp: dataCanvas.data.cost_structure, showBtn: (dataCanvas.data.cost_structure.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-channels.png", item: "Channels", resp: dataCanvas.data.channels, showBtn: (dataCanvas.data.channels.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-value-propositions.png", item: "Value Propositions", resp: dataCanvas.data.value_propositions, showBtn: (dataCanvas.data.value_propositions.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-customer-relationship.png", item: "Customer Relationship", resp: dataCanvas.data.customer_relationship, showBtn: (dataCanvas.data.customer_relationship.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-customer-segments.png", item: "Customer Segments", resp: dataCanvas.data.customer_segments, showBtn: (dataCanvas.data.customer_segments.length) > 100 ? true : false });
            this.listCanvas.push({ icon: "assets/images/ico-revenue-streams.png", item: "Revenue Streams", resp: dataCanvas.data.revenue_streams, showBtn: (dataCanvas.data.revenue_streams.length) > 100 ? true : false });

          }

        } else {
          this.hideCargando();
        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.getCanvas: ', err);
      });

  }

  confirmGrading() {

    let params = {
      idTeam: this.idTeam,
      totalFinancing: this.totalFinancing,
      key_partners_value: this.key_partners_value,
      key_activities_value: this.key_activities_value,
      key_resources_value: this.key_resources_value,
      cost_structure_value: this.cost_structure_value,
      channels_value: this.channels_value,
      value_propositions_value: this.value_propositions_value,
      customer_relationship_value: this.customer_relationship_value,
      customer_segments_value: this.customer_segments_value,
      revenue_streams_value: this.revenue_streams_value
    }

    this.teacherService.confirmGrading(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.status == "OK") {

          this.teacherService.setStatusGrading(1);
          this.teacherService.setIdTeam(this.idTeam);
          this.router.navigateByUrl('/teacher/reviewCanvas');
        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.getCanvas: ', err);
      });

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  onChangeDropdown(tipo, $event) {

    let config = this.getDataCanvas(tipo);

    let grade = 0;

    switch ($event.value) {
      case 1:
        grade = config.grades[2].value;
        break;

      case 2:
        grade = config.grades[1].value;
        break;

      case 3:
        grade = config.grades[0].value;
        break;

    }

    switch (tipo) {

      case 'Key Partners':
        this.key_partners_value = grade;
        this.key_partners_visit = true;
        break;
      case 'Key Activities':
        this.key_activities_value = grade;
        this.key_activities_visit = true;
        break;
      case 'Key Resources':
        this.key_resources_value = grade;
        this.key_resources_visit = true;
        break;
      case 'Cost Structure':
        this.cost_structure_value = grade;
        this.cost_structure_visit = true;
        break;
      case 'Channels':
        this.channels_value = grade;
        this.channels_visit = true;
        break;
      case 'Value Propositions':
        this.value_propositions_value = grade;
        this.value_propositions_visit = true;
        break;
      case 'Customer Relationship':
        this.customer_relationship_value = grade;
        this.customer_relationship_visit = true;
        break;
      case 'Customer Segments':
        this.customer_segments_value = grade;
        this.customer_segments_visit = true;
        break;
      case 'Revenue Streams':
        this.revenue_streams_value = grade;
        this.revenue_streams_visit = true;
        break;
    }

    this.totalGrade = this.key_partners_value + this.key_activities_value + this.key_resources_value +
      this.cost_structure_value + this.channels_value + this.value_propositions_value + this.customer_relationship_value +
      this.customer_segments_value + this.revenue_streams_value;

    if (this.totalGrade >= 5) {

      this.totalFinancing = this.getValueStarterMarket(this.idAreaSelect, this.seasonSelect, this.totalGrade);

      this.totalFinancingString = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(this.totalFinancing);

    }


    if (this.key_partners_visit && this.key_activities_visit && this.key_resources_visit && this.channels_visit &&
      this.value_propositions_visit && this.customer_relationship_visit && this.customer_segments_visit) {
      this.btnDisabled = false;
    } else {
      this.btnDisabled = true;
    }



  }

  goBack() {
    this.teacherService.setIdTeam(this.idTeam);
    this.router.navigateByUrl('/teacher/reviewCanvas');
  }

  showModal(template) {

    this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
  }

  closeModal() {

    switch (this.selectCanvas) {
      case 'key_partners':
        this.key_partners = this.data;
        break;

      case 'key_activities':
        this.key_activities = this.data;
        break;

      case 'key_resources':
        this.key_resources = this.data;
        break;

      case 'cost_structure':
        this.cost_structure = this.data;
        break;

      case 'channels':
        this.channels = this.data;
        break;

      case 'value_propositions':
        this.value_propositions = this.data;
        break;

      case 'customer_relationship':
        this.customer_relationship = this.data;
        break;

      case 'customer_segments':
        this.customer_segments = this.data;
        break;

      case 'revenue_streams':
        this.revenue_streams = this.data;
        break;

    }

    this.modalRef.hide();
  }

  showModalCanvas(template, tipo) {

    switch (tipo) {
      case 'Key Partners':
        this.data = this.key_partners;
        this.selectCanvas = 'key_partners';
        break;
      case 'Key Activities':
        this.data = this.key_activities;
        this.selectCanvas = 'key_activities';

        break;
      case 'Key Resources':
        this.data = this.key_resources;
        this.selectCanvas = 'key_resources';

        break;
      case 'Cost Structure':
        this.data = this.cost_structure;
        this.selectCanvas = 'cost_structure';

        break;
      case 'Channels':
        this.data = this.channels;
        this.selectCanvas = 'channels';

        break;
      case 'Value Propositions':
        this.data = this.value_propositions;
        this.selectCanvas = 'value_propositions';

        break;
      case 'Customer Relationship':
        this.data = this.customer_relationship;
        this.selectCanvas = 'customer_relationship';

        break;

      case 'Customer Segments':
        this.data = this.customer_segments;
        this.selectCanvas = 'customer_segments';

        break;

      case 'Revenue Streams':
        this.data = this.revenue_streams;
        this.selectCanvas = 'revenue_streams';

        break;
    }

    this.dataModal = this.getDataCanvas(tipo);

    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  closeModalCanvas() {

    switch (this.selectCanvas) {
      case 'key_partners':
        this.key_partners = this.data;
        break;

      case 'key_activities':
        this.key_activities = this.data;
        break;

      case 'key_resources':
        this.key_resources = this.data;
        break;

      case 'cost_structure':
        this.cost_structure = this.data;
        break;

      case 'channels':
        this.channels = this.data;
        break;

      case 'value_propositions':
        this.value_propositions = this.data;
        break;

      case 'customer_relationship':
        this.customer_relationship = this.data;
        break;

      case 'customer_segments':
        this.customer_segments = this.data;
        break;

      case 'revenue_streams':
        this.revenue_streams = this.data;
        break;

    }

    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  getDataCanvas(tipo) {

    type ConfigEntities = {
      name: string;
      key: string;
      icon: string;
      description: string;
      grades: any[];
    };

    let config: ConfigEntities = {
      name: '',
      key: '',
      icon: '',
      description: '',
      grades: []
    };

    switch (tipo) {
      case 'Key Partners':
        config = {
          name: 'Key Partners',
          key: 'key_partners',
          icon: '/images/ico-key-partners.png',
          description: 'Who are our partners in building our Value Proposition?<br>What key resources are we receiving from them? What key activities do our partners develop?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Key Activities':
        config = {
          name: 'Key Activities',
          key: 'key_activities',
          icon: '/images/ico-key-activities.png',
          description: 'What key activities does our value proposition require?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Key Resources':
        config = {
          name: 'Key Resources',
          key: 'key_resources',
          icon: '/images/ico-key-resources.png',
          description: 'What key resources does our value proposition require?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Cost Structure':
        config = {
          name: 'Cost Structure',
          key: 'cost_structure',
          icon: '/images/ico-cost-structure.png',
          description: 'What are the most relevant costs of our Value Proposition?<br>Which are the most expensive key activities?<br>What are the most expensive key resources?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Channels':
        config = {
          name: 'Channels',
          key: 'channels',
          icon: '/images/ico-channels.png',
          description: 'How will we inform groups of interest/stakeholders about our products? How will we deliver our products to our groups of interest/stakeholders?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Value Propositions':
        config = {
          name: 'Value Propositions',
          key: 'value_propositions',
          icon: '/images/ico-value-propositions.png',
          description: 'What needs of the interest groups/stakeholders does the organization satisfy? Why should interest groups/stakeholders choose our organization?<br>What combination of services does the organization offer to each group of interest/stakeholder?',
          grades: [{
            label: 'VERY GOOD',
            value: 2
          }, {
            label: 'GOOD',
            value: 1
          }, {
            label: 'NOT GOOD',
            value: 0.5
          }]
        };

        break;
      case 'Customer Relationship':
        config = {
          name: 'Customer Relationship',
          key: 'customer_relationship',
          icon: '/images/ico-customer-relationship.png',
          description: 'What relations have been established with interest groups/stakeholders?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Customer Segments':
        config = {
          name: 'Customer Segments',
          key: 'customer_segments',
          icon: '/images/ico-customer-segments.png',
          description: 'What are the groups of interest/ stakeholders and beneficiaries of our activities?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'Revenue Streams':
        config = {
          name: 'Revenue Streams',
          key: 'revenue_streams',
          icon: '/images/ico-revenue-streams.png',
          description: 'What are the revenue streams?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
    }

    return config;

  }

  getValueStarterMarket(idArea, season, totalGrade) {

    let result = 0;

    switch (idArea) {
      case 1:

        if (season == "LOW") {
          result = GameAreaSetting.AGROINDUSTRY.LOW.base_starter_market;
        } else if (season == "MIDDLE") {
          result = GameAreaSetting.AGROINDUSTRY.MIDDLE.base_starter_market;
        } else {
          result = GameAreaSetting.AGROINDUSTRY.HIGH.base_starter_market;
        }

        break;

      case 2:

        if (season == "LOW") {
          result = GameAreaSetting.MANUFACTURING.LOW.base_starter_market;
        } else if (season == "MIDDLE") {
          result = GameAreaSetting.MANUFACTURING.MIDDLE.base_starter_market;
        } else {
          result = GameAreaSetting.MANUFACTURING.HIGH.base_starter_market;
        }

        break;

      case 3:

        if (season == "LOW") {
          result = GameAreaSetting.DELIVERY.LOW.base_starter_market;
        } else if (season == "MIDDLE") {
          result = GameAreaSetting.DELIVERY.MIDDLE.base_starter_market;
        } else {
          result = GameAreaSetting.DELIVERY.HIGH.base_starter_market;
        }

        break;

    }

    // TODO: Cambiar formula
    result = (result * (totalGrade * 10)) / 100;

    return result;

  }

}
