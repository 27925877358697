import { Injectable } from '@angular/core';
import { GlobalVar } from '../../../../config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TeacherService {
    private url_base: string;
    private idTeamSelect: number;
    private statusGrading: number;

    constructor(
        private http: HttpClient
    ) {
        this.url_base = GlobalVar.URL_BASE;
    }

    private static handleError(error: any): Promise<any> {
        console.error('Ha ocurrido un error: ', error);
        return Promise.reject(error.message || error);
    }

    saveClass(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "teacher/saveClass", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeacherService.handleError));
    }

    getAllClass(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "teacher/getAllClass", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeacherService.handleError));
    }

    getAllTeams(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "teacher/getAllTeams", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeacherService.handleError));
    }

    sendEmailTeams(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "teacher/sendEmailTeams", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeacherService.handleError));
    }

    confirmGrading(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "teacher/confirmGrading", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeacherService.handleError));
    }

    changeMarket(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "teacher/changeMarket", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeacherService.handleError));
    }


    setIdTeam(idTeam) {
        this.idTeamSelect = idTeam;
    }

    getIdTeam() {
        return this.idTeamSelect;
    }

    setStatusGrading(id) {
        this.statusGrading = id;
    }

    getStatusGrading() {
        return this.statusGrading;
    }

    // *************** SECCION MAPEO ***************

}

