import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../../project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';


@Component({
  selector: 'app-landing-canvas',
  templateUrl: './landingCanvas.component.html',
  styleUrls: ['./landingCanvas.component.scss']
})
export class LandingCanvasComponent implements OnInit {

  modalRef: BsModalRef;

  manufactureVisited = false;
  deliveryVisited = false;
  pizzaVisited = false;
  config = {};
  disabledBtn = true;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ux: UxService,
    private modalService: BsModalService,

  ) {

    var classFondo = document.getElementsByClassName("fondoQuiz")[0];

    if (classFondo != null) {

      if (classFondo.className = "fondoQuiz") {
        classFondo.className = "fondo";
      }
    }

    this.manufactureVisited = false;
    this.deliveryVisited = false;
    this.pizzaVisited = false;

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }
  }

  startCanvas() {
    this.router.navigateByUrl('/team/canvas');
  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showModal(template, tipo) {

    switch (tipo) {
      case 'manufacture':

        this.config = {
          seasonalTurnout: 'assets/images/seasonal-turnout-75.png',
          marketCovered: 50,
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 29.50,
        }

        this.manufactureVisited = true;

        break;

      case 'delivery':

        this.config = {
          seasonalTurnout: 'assets/images/seasonal-turnout-75.png',
          marketCovered: 25,
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 2,
        }

        this.deliveryVisited = true;

        break;

      case 'pizza':
        this.config = {
          seasonalTurnout: 'assets/images/seasonal-turnout-75.png',
          marketCovered: 90,
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 13,
        }

        this.pizzaVisited = true;

        break;

    }

    if (this.manufactureVisited && this.deliveryVisited && this.pizzaVisited) {
      this.disabledBtn = false;
    }

    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
