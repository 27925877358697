import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-attendee-delivery',
  templateUrl: './attendeeDelivery.component.html',
  styleUrls: ['./attendeeDelivery.component.scss']
})
export class AttendeeDeliveryComponent implements OnInit {

  modalRef: BsModalRef;

  selectAssitant = false;
  selectTwoAssistant = false;
  selectThreeAssistant = false;

  hourAssistant = 0;
  hourTwoAssistant = 0;
  hourThreeAssistant = 0;

  valueAssitant = 0;
  valueTwoAssistant = 0;
  valueThreeAssistant = 0;

  sellDeliveryPizza = 0;
  priceHours = 0.5;
  valueTotal = 0;
  amountInitial = 0;
  seasonSelect = "";

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.getValuesDelivery();

  }

  ngOnInit() {
  }

  getValuesDelivery() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesDelivery(param)

      .subscribe(data => {
        if (data.status == "OK") {

          this.hideCargando();

          this.seasonSelect = data.data.season;

          this.sellDeliveryPizza = data.data.sellDeliveryPizza;

          if (data.data.first_delivery > 0) {
            this.selectAssitant = true;
            this.hourAssistant = data.data.first_delivery;
            this.valueAssitant = this.priceHours * this.hourAssistant;
          }

          if (data.data.second_delivery > 0) {
            this.selectTwoAssistant = true;
            this.hourTwoAssistant = data.data.second_delivery;
            this.valueTwoAssistant = this.priceHours * this.hourTwoAssistant;
          }

          if (data.data.third_delivery > 0) {
            this.selectThreeAssistant = true;
            this.hourThreeAssistant = data.data.third_delivery;
            this.valueThreeAssistant = this.priceHours * this.hourThreeAssistant;
          }


          // switch (this.seasonSelect) {
          //   case "low":

          //     if (data.data.first_delivery > 0) {
          //       this.selectAssitant = true;

          //       this.hourAssistant = data.data.first_delivery;
          //       this.valueAssitant = this.priceHours * this.hourAssistant;
          //     }

          //     break;

          //   case "mid":

          //     if (data.data.first_delivery > 0) {
          //       this.selectAssitant = true;
          //       this.valueAssitant = (this.selectAssitant) ? this.priceAssistant : 0;
          //     }

          //     if (data.data.second_delivery > 0) {
          //       this.selectTwoAssistant = true;
          //       this.valueTwoAssistant = (this.selectTwoAssistant) ? this.priceTwoAssistant : 0;
          //     }

          //     break;

          //   case "high":

          //     this.priceAssistant = 630;
          //     this.priceTwoAssistant = 630;
          //     this.priceThreeAssistant = 630;

          //     if (data.data.first_delivery > 0) {
          //       this.selectAssitant = true;
          //       this.valueAssitant = (this.selectAssitant) ? this.priceAssistant : 0;
          //     }

          //     if (data.data.second_delivery > 0) {
          //       this.selectTwoAssistant = true;
          //       this.valueTwoAssistant = (this.selectTwoAssistant) ? this.priceTwoAssistant : 0;
          //     }

          //     if (data.data.third_delivery > 0) {
          //       this.selectThreeAssistant = true;
          //       this.valueThreeAssistant = (this.selectThreeAssistant) ? this.priceThreeAssistant : 0;
          //     }

          //     break;


          // }

          this.valueTotal = this.valueAssitant + this.valueTwoAssistant + this.valueThreeAssistant;

        }
      });

  }

  buyAssistant() {

    this.showCargando();

    try {

      if (this.valueTotal > 0) {

        let param = {
          selectAssitant: this.hourAssistant,
          selectTwoAssistant: this.hourTwoAssistant,
          selectThreeAssistant: this.hourThreeAssistant,
          valueTotal: this.valueTotal,
          valueTotalFinish: false,
          idTeam: this.idTeam
        }

        this.teamService.updateCostFijoDelivery(param)
          .subscribe(data => {
            if (data.status == "OK") {
              this.hideCargando();
              localStorage.setItem('valueTotalAssistant', this.valueTotal.toString());
              this.goTo("equipment");
            } else {
              this.hideCargando();
              this.ux.notificacion("Error, favor intentar nuevamente", 'error');
            }
          });

      } else {
        this.hideCargando();
        this.ux.notificacion('You must hire at least one staff.', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  goTo(page) {

    switch (page) {

      case 'attendee':
        this.router.navigateByUrl('/team/game/delivery/attendee');
        break;

      case 'equipment':
        this.router.navigateByUrl('/team/game/delivery/equipment');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/delivery/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/delivery/location');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updateAttendee(tipo) {

    if (tipo == "first") {
      this.valueAssitant = this.priceHours * this.hourAssistant;
    } else if (tipo == "second") {
      this.valueTwoAssistant = this.priceHours * this.hourTwoAssistant;
    } else if (tipo == "three") {
      this.valueThreeAssistant = this.priceHours * this.hourThreeAssistant;
    }

    this.valueTotal = this.valueAssitant + this.valueTwoAssistant + this.valueThreeAssistant;
  }

  showModalInfo(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
