import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../../../project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TeamService } from 'src/app/project/services/team.service';
import { SelectItem } from 'primeng/components/common/selectitem';

@Component({
  selector: 'app-sell-pizza',
  templateUrl: './sellPizza.component.html',
  styleUrls: ['./sellPizza.component.scss']
})
export class SellPizzaComponent implements OnInit {

  modalRef: BsModalRef;
  dropdownGrade: SelectItem[];
  totalFinancing = 0;
  totalFinancingString = "";
  idTeam = 0;
  idAreaSelect = 0;
  costFixed = 0;
  costVar = 0;
  utility = 0;
  seasonSelect = "";
  sellPizza = 0;
  sellPrice = 0;
  amountInitial = 0;
  startGame = false;
  changeMaket = "";

  idTime: any;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  constructor(
    private router: Router,
    private ux: UxService,
    private teamService: TeamService,
    private modalService: BsModalService,
  ) {

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.getValueCost();
    this.getIngredient();

    this.updateStateDir();
  }

  ngOnInit() {
  }

  getValueCost() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValueCost(param)
      .subscribe(data => {
        if (data.status == "OK") {

          this.costFixed = data.data.cost_fijo;
          this.costVar = data.data.cost_var;
          this.seasonSelect = data.data.season;

          this.hideCargando();
        } else {
          this.hideCargando();
          this.ux.notificacion("Error, favor intentar nuevamente", 'error');
        }
      });
  }

  getIngredient() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getIngredientPizza(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          this.sellPizza = data.data.sellPizza;
          this.sellPrice = data.data.sellPrice;
          this.amountInitial = data.data.amountInitial;
          this.changeMaket = data.data.changeMaket;

          // Utilidad = cant * precio - todos los costos

          this.utility = (this.sellPizza * this.sellPrice) - (this.costFixed + this.costVar)
          this.utility = parseFloat(parseFloat(this.utility.toString()).toFixed(2));

        }
      });

  }

  updateStateDir() {

    let param = {
      tipo: null,
      idTeam: this.idTeam,
      dir: 'App/Game/Phases/Restart'
    }

    this.teamService.updateStateDir(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
        }
      });

  }

  restartGame(template) {
    this.startGame = true;

    this.idTime = setInterval(() => {

      this.verifyChangeMarket(template);

    }, 3000);

  }

  startGameLetGo() {

    switch (this.idAreaSelect) {
      case 1:
        this.closeModal();
        localStorage.setItem('idArea', this.idAreaSelect.toString());
        localStorage.setItem('showMarket', true.toString());
        this.router.navigateByUrl('team/game/pizza/costVariable');
        break;

      case 2:
        this.closeModal();
        localStorage.setItem('idArea', this.idAreaSelect.toString());
        localStorage.setItem('showMarket', true.toString());
        this.router.navigateByUrl('team/game/manufacture/packing');

        break;
      case 3:
        this.closeModal();
        localStorage.setItem('idArea', this.idAreaSelect.toString());
        localStorage.setItem('showMarket', true.toString());
        this.router.navigateByUrl('team/game/delivery/attendee');

        break;
    }

  }

  verifyChangeMarket(template) {

    let param = {
      idTeam: this.idTeam,
      idArea: 1
    }

    this.changeMaket = ""

    this.teamService.verifyChangeMarket(param)

      .subscribe(data => {
        if (data.status == "OK") {

          if (data.data) {
            clearInterval(this.idTime);

            this.getIngredient();
            this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
          }

        }
      });

  }


  //  *********************************** FUNCIONES DE VISTA ***********************************

  goTo() {
    this.closeModal();
    this.router.navigateByUrl('team/canvas/canvasRenew');
  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
