import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-cost-variable-pizza',
  templateUrl: './costVariablePizza.component.html',
  styleUrls: ['./costVariablePizza.component.scss']
})
export class CostVariablePizzaComponent implements OnInit {

  modalRefInfo: BsModalRef;

  cantFlour = 0;
  cantOil = 0;
  cantSalt = 0;
  cantYeast = 0;
  cantTomato = 0;
  cantMozzarella = 0;
  sellPizza = 0;

  valueFlour: number = 0;
  valueOil: number = 0;
  valueSalt: number = 0;
  valueYeast: number = 0;
  valueTomato: number = 0;
  valueMozzarella: number = 0;
  valueTotal: number = 0;

  priceFlour = 0;
  priceOil = 0;
  priceSalt = 0;
  priceYeast = 0;
  priceTomato = 0;
  priceMozzarella = 0;
  currentModalIndex = 1;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.priceFlour = 0.30;
    this.priceOil = 0.24;
    this.priceSalt = 0.032;
    this.priceYeast = 0.40;
    this.priceTomato = 0.12;
    this.priceMozzarella = 0.96;

    this.getIngredient();

  }

  ngOnInit() {
  }

  getIngredient() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getIngredientPizza(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          this.cantFlour = data.data.flour
          this.cantOil = data.data.oil
          this.cantSalt = data.data.spices
          this.cantYeast = data.data.yeast
          this.cantTomato = data.data.tomato
          this.cantMozzarella = data.data.chesse
          this.sellPizza = data.data.sellPizza

          this.valueFlour = parseFloat((this.sellPizza * this.priceFlour).toFixed(2));
          this.valueOil = parseFloat((this.sellPizza * this.priceOil).toFixed(2));
          this.valueSalt = parseFloat((this.sellPizza * this.priceSalt).toFixed(2));
          this.valueYeast = parseFloat((this.sellPizza * this.priceYeast).toFixed(2));
          this.valueTomato = parseFloat((this.sellPizza * this.priceTomato).toFixed(2));
          this.valueMozzarella = parseFloat((this.sellPizza * this.priceMozzarella).toFixed(2));

          this.valueTotal = parseFloat((this.valueFlour + this.valueOil + this.valueSalt + this.valueYeast + this.valueTomato + this.valueMozzarella).toFixed(2));;

        }
      });

  }

  buyIngredientVariable() {

    this.showCargando();

    if (this.valueTotal > 0) {

      let param = {
        flour: this.valueFlour,
        oil: this.valueOil,
        salt: this.valueSalt,
        yeast: this.valueYeast,
        tomato: this.valueTomato,
        chesse: this.valueMozzarella,
        idTeam: this.idTeam
      }

      this.teamService.buyIngredientVariable(param)

        .subscribe(data => {
          if (data.status == "OK") {
            this.hideCargando();
            localStorage.setItem('valueTotalIngredient', this.valueTotal.toString());
            this.goTo("packing");
          }
        });

    } else {
      this.hideCargando();
      this.ux.notificacion('You must buy at least one ingredient type.', 'warning');
    }

  }

  goTo(page) {

    switch (page) {

      case 'ingredient':
        this.router.navigateByUrl('/team/game/pizza/costVariable');
        break;

      case 'packing':
        this.router.navigateByUrl('/team/game/pizza/packing');
        break;

      case 'attendee':
        this.router.navigateByUrl('/team/game/pizza/attendee');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/pizza/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/pizza/location');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updateIngredient(tipo, ingredient) {

    if (tipo == "add") {

      switch (ingredient) {
        case "flour":
          this.cantFlour++;
          this.valueFlour = parseFloat((this.cantFlour * this.priceFlour).toFixed(2));
          break;
        case "oil":
          this.cantOil++;
          this.valueOil = parseFloat((this.cantOil * this.priceOil).toFixed(2));
          break;
        case "salt":
          this.cantSalt++;
          this.valueSalt = parseFloat((this.cantSalt * this.priceSalt).toFixed(2));
          break;
        case "yeast":
          this.cantYeast++;
          this.valueYeast = parseFloat((this.cantYeast * this.priceYeast).toFixed(2));
          break;
        case "tomato":
          this.cantTomato++;
          this.valueTomato = parseFloat((this.cantTomato * this.priceTomato).toFixed(2));
          break;
        case "mozzarella":
          this.cantMozzarella++;
          this.valueMozzarella = parseFloat((this.cantMozzarella * this.priceMozzarella).toFixed(2));
          break;
      }

    } else if (tipo == "rest") {

      switch (ingredient) {
        case "flour":
          if (this.cantFlour >= 1) {
            this.cantFlour--;
            this.valueFlour = parseFloat((this.cantFlour * this.priceFlour).toFixed(2));
          }
          break;
        case "oil":
          if (this.cantOil >= 1) {
            this.cantOil--;
            this.valueOil = parseFloat((this.cantOil * this.priceOil).toFixed(2));
          }
          break;
        case "salt":
          if (this.cantSalt >= 1) {
            this.cantSalt--;
            this.valueSalt = parseFloat((this.cantSalt * this.priceSalt).toFixed(2));
          }
          break;
        case "yeast":
          if (this.cantYeast >= 1) {
            this.cantYeast--;
            this.valueYeast = parseFloat((this.cantYeast * this.priceYeast).toFixed(2));
          }
          break;
        case "tomato":
          if (this.cantTomato >= 1) {
            this.cantTomato--;
            this.valueTomato = parseFloat((this.cantTomato * this.priceTomato).toFixed(2));
          }
          break;
        case "mozzarella":
          if (this.cantMozzarella >= 1) {
            this.cantMozzarella--;
            this.valueMozzarella = parseFloat((this.cantMozzarella * this.priceMozzarella).toFixed(2));
          }
          break;
      }

    }

    this.valueTotal = parseFloat((this.valueFlour + this.valueOil + this.valueSalt + this.valueYeast + this.valueTomato + this.valueMozzarella).toFixed(2));;

  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  showModalInfo(template) {
    this.currentModalIndex = 1;
    this.modalRefInfo = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
  }

  changeModalIndex(tipo) {

    if (tipo == "+" && this.currentModalIndex < 3) {
      this.currentModalIndex++;
    } else if (tipo == "-" && this.currentModalIndex > 1) {
      this.currentModalIndex--;
    }

  }

  closeModal() {
    this.modalRefInfo.hide();
  }

}
