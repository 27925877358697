import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-location-manufacture',
  templateUrl: './locationManufacture.component.html',
  styleUrls: ['./locationManufacture.component.scss']
})
export class LocationManufactureComponent implements OnInit {

  modalRef: BsModalRef;

  selectNorth = false;
  selectCenter = false;
  selectSouth = false;

  valueNorth: number = 0;
  valueCenter: number = 0;
  valueSouth: number = 0;

  valueTotal: number = 0;

  priceNorth = 0;
  priceCenter = 0;
  priceSouth = 0;

  location = "";

  amountInitial = 0;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.priceNorth = 340;
    this.priceCenter = 400;
    this.priceSouth = 320;

    this.getValuesManufacture();

  }

  ngOnInit() {
  }

  getValuesManufacture() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesManufacture(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          switch (data.data.location) {
            case "north":
              this.selectNorth = true;
              this.valueNorth = (this.selectNorth) ? this.priceNorth : 0;
              this.location = "north";
              break;

            case "center":
              this.selectCenter = true;
              this.valueCenter = (this.selectCenter) ? this.priceCenter : 0;
              this.location = "center";
              break;

            case "south":
              this.selectSouth = true;
              this.valueSouth = (this.selectSouth) ? this.priceSouth : 0;
              this.location = "south";
              break;

          }

        }
      });

  }

  buyLocation() {

    this.showCargando();

    try {

      if (this.selectNorth || this.selectCenter || this.selectSouth) {

        let valueCostAsistent = parseFloat(localStorage.getItem('valueTotalAssistant').toString()).toFixed(2);
        let valueCostPromotion = parseFloat(localStorage.getItem('valueTotalPromotion').toString()).toFixed(2);
        let valueCostLocation = (this.selectNorth) ? this.priceNorth : (this.selectCenter) ? this.priceCenter : (this.selectSouth) ? this.priceSouth : 0;

        let valueTotal = parseFloat(valueCostAsistent) + parseFloat(valueCostPromotion) + valueCostLocation; // Se agrega maquinaria y otros costos        

        let param = {
          location: this.location,
          valueTotal: valueTotal,
          valueTotalFinish: true,
          idTeam: this.idTeam
        }

        this.teamService.updateCostFijoManufacture(param)
          .subscribe(data => {
            if (data.status == "OK") {
              this.hideCargando();

              localStorage.setItem('valueTotalLocation', this.valueTotal.toString());

              this.goTo("offer");

            } else {
              this.hideCargando();
              this.ux.notificacion("Error, favor intentar nuevamente", 'error');
            }
          });

      } else {
        this.hideCargando();
        this.ux.notificacion('You must hire at least one staff.', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  calculateStartAmount() {

    this.amountInitial = parseFloat(localStorage.getItem('valueTotalAssistant')) + parseFloat(localStorage.getItem('valueTotalIngredient')) + parseFloat(localStorage.getItem('valueTotalPacking')) + parseFloat(localStorage.getItem('valueTotalLocation')) + parseFloat(localStorage.getItem('valueTotalPromotion'));
    this.amountInitial = this.amountInitial + (this.amountInitial * 0.3);

  }

  goTo(page) {

    switch (page) {

      case 'packing':
        this.router.navigateByUrl('/team/game/manufacture/packing');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/manufacture/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/manufacture/location');
        break;

      case 'offer':
        this.router.navigateByUrl('/team/game/manufacture/offer');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updateLocation(tipo) {

    switch (tipo) {
      case 'north':
        this.selectNorth = true;
        this.selectCenter = false;
        this.selectSouth = false;

        this.valueTotal = this.priceNorth;
        this.location = "north";

        break;

      case 'center':
        this.selectNorth = false;
        this.selectCenter = true;
        this.selectSouth = false;
        this.valueTotal = this.priceCenter;
        this.location = "center";
        break;

      case 'south':
        this.selectNorth = false;
        this.selectCenter = false;
        this.selectSouth = true;
        this.valueTotal = this.priceSouth;
        this.location = "south";
        break;
    }

  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
