import { Injectable } from '@angular/core';
import { GlobalVar } from '../../../../config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TeamService {
    private url_base: string;
    private listChoice = [];
    private idTeamSelect: number;

    constructor(
        private http: HttpClient
    ) {
        this.url_base = GlobalVar.URL_BASE;
    }

    private static handleError(error: any): Promise<any> {
        console.error('Ha ocurrido un error: ', error);
        return Promise.reject(error.message || error);
    }

    getAllAnswers(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getAllAnswers", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    startQuiz(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/startQuiz", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    saveQuiz(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/saveQuiz", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    getResultQuiz(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getResultQuiz", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    saveCanvas(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/saveCanvas", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    reviewCanvas(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/reviewCanvas", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateStateDir(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateStateDir", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    verifyChangeMarket(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/verifyChangeMarket", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    getCanvas(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getCanvas", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    // ************* GAME PIZZA *************

    updateTypeSeason(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateTypeSeason", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateSellPizza(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateSellPizza", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateCostVar(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateCostVar", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateCostFijo(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateCostFijo", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    getValueCost(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getValueCost", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updatePorcentageMarket(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updatePorcentageMarket", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    buyIngredientVariable(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/buyIngredientVariable", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    getIngredientPizza(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getIngredientPizza", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    // ************* GAME MANUFACTURE *************

    getValuesManufacture(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getValuesManufacture", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateCostVarManufacture(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateCostVarManufacture", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateCostFijoManufacture(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateCostFijoManufacture", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    // ************* GAME DELIVERY *************

    getValuesDelivery(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/getValuesDelivery", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }

    updateCostFijoDelivery(arg: any): Observable<any> {
        return this.http
            .post(this.url_base + "team/updateCostFijoDelivery", { arg: JSON.stringify(arg) })
            .pipe(map(data => data))
            .pipe(catchError(TeamService.handleError));
    }


    setListChoice(list) {
        this.listChoice = list;
    }

    getListChoice() {
        return this.listChoice;
    }

    setIdTeam(idTeam) {
        this.idTeamSelect = idTeam;
    }

    getIdTeam() {
        return this.idTeamSelect;
    }


    // *************** SECCION MAPEO ***************

}

