import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';


@Component({
  selector: 'app-result-quiz',
  templateUrl: './resultQuiz.component.html',
  styleUrls: ['./resultQuiz.component.scss']
})
export class ResultQuizComponent implements OnInit {

  listAnswers = [];
  resultQuiz = false;
  cont = 0;
  idTeam = 0;
  score = 0;
  row: any

  modalRef: BsModalRef;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.getResultQuiz();

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }
  }

  getResultQuiz() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getResultQuiz(param)

      .subscribe(data => {
        if (data.status == "OK") {

          this.resultQuiz = (data.data.is_passed == 1) ? true : false;
          this.score = data.data.score;

          this.hideCargando();

        }
      });

  }

  tryAgain() {
    this.router.navigateByUrl('team/quiz/landing');
  }

  gotoMarket() {

    this.showCargando();

    let dir = 'App/Game/Phases/AgoraMarket';

    let param = {
      idTeam: this.idTeam,
      dir: dir
    }

    this.teamService.updateStateDir(param)

      .subscribe(data => {
        if (data.status == "OK") {

          this.hideCargando();
          this.router.navigateByUrl('/team/agoraMarket');

        }
      });

    this.hideCargando();

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showModalResultQuiz(template) {

    this.listAnswers = [];

    // console.log(this.teamService.getListChoice());

    this.teamService.getListChoice().forEach(element => {

      let resp;
      let resp_correct;
      let correct = false;

      element.answers.forEach(element => {
        if (element.active) {
          resp = element.letter + " - " + element.text
        }

        if (element.is_correct == 1) {
          resp_correct = element.letter + " - " + element.text;
        }

        if (element.active && element.is_correct == 1) {
          correct = true;
        }
      });

      let obj = {
        question: element.question,
        resp: resp,
        resp_correct: resp_correct,
        correct: correct
      }

      this.listAnswers.push(obj);

    });

    this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
  }

  closeModalResultQuiz() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
