import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeacherService } from '../..//project/services/teacher.service';
import { UxService } from '../../project/services/ux.service';

@Component({
  selector: 'app-new-class',
  templateUrl: './newClass.component.html',
  styleUrls: ['./newClass.component.scss']
})
export class NewClassComponent implements OnInit {

  accederForm: FormGroup;
  submitted = false;
  classes = [];

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teacherService: TeacherService,
    private ux: UxService
  ) {

    this.classes.push({ name: "", section: "", teams: 0 })

  }

  ngOnInit() {
    if (!localStorage.getItem('id')) {
      this.router.navigateByUrl('/login/teacher');
    }
  }

  saveClass() {

    this.showCargando();

    if (this.classes.length > 1) {

      let params = {
        user: localStorage.getItem('id'),
        section: this.classes,
      }

      this.teacherService.saveClass(params)

        .subscribe(data => {
          if (data.status == "OK") {

            if (data.data == "Insert") {
              this.hideCargando();
              this.router.navigateByUrl('/teacher/viewAllClass');
            } else {
              this.ux.notificacion('Name class duplicated.', 'danger');
              this.hideCargando();
            }

          }
        });

    } else {
      this.hideCargando();
      this.ux.notificacion('You must add a class.', 'danger');
    }
  }



  //  *********************************** FUNCIONES DE VISTA ***********************************

  addClass(item, index) {

    if (item.name != "" && item.section != "" && item.teams > 0) {
      this.classes[index] = { name: item.name, section: item.section, teams: item.teams };
      this.classes.push({ name: "", section: "", teams: 0 })
    } else {
      this.ux.notificacion('Debe completa los campos de una clase.', 'warning');
    }

  }

  removeClass(item) {
    this.classes = this.classes.filter(element => { return element !== item });
  }

  numTeams(tipo, item) {

    if (tipo == "add") {
      item.teams = item.teams + 1;
    } else if (tipo == "rest" && item.teams > 0) {
      item.teams = item.teams - 1;
    }

  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
