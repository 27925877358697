import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { UsuarioService } from '../../app/project/services/usuario.service';
import { UxService } from '../project/services/ux.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  email: any;
  pass: any;
  errorMessage = "";

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private ux: UxService

  ) { }

  ngOnInit() {
    if (localStorage.getItem('id')) {
      this.router.navigateByUrl('/login/teacher');
    }
  }

  login() {

    this.errorMessage = "";

    this.showCargando();

    if (this.email == "" || this.email == null || this.pass == "" || this.pass == null) {
      this.ux.notificacion('Credentials are not valid.', 'danger');
      this.hideCargando();
    } else {

      let params = {
        correo: this.email,
        contrasena: this.pass,
      }

      this.usuarioService.login(params)

        .subscribe(dataLogin => {

          if (dataLogin.data.validado == true) {

            this.hideCargando();

            localStorage.setItem('id', dataLogin.data.infoUser.id);
            localStorage.setItem('user', "teacher");
            localStorage.setItem('name', dataLogin.data.infoUser.name);
            localStorage.setItem('lastname', dataLogin.data.infoUser.lastname);
            localStorage.setItem('correo', dataLogin.data.infoUser.correo);

            this.router.navigateByUrl('/teacher/newClass');

          } else if (dataLogin.data.validado == false) {

            this.hideCargando();
            this.errorMessage = "Credentials are not valid.";
            this.ux.notificacion('Credentials are not valid.', 'danger');

          }

        }, err => {
          this.hideCargando();
          this.ux.alerta(err);
          console.log('Error al invocar a usuarioService.login: ', err);
        });
    }
  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
