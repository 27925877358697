import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { AppComponent } from './app.component';
import { CabeceraComponent } from './base/cabecera/cabecera.component';
import { PieComponent } from './base/pie/pie.component';
import { ErrorComponent } from './base/error/error.component';
import { AlertaComponent } from '../app/base/alerta/alerta.component';
import { CargandoComponent } from '../app/base/cargando/cargando.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxNotifierModule } from 'ngx-notifier';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Páginas

import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { LoginTeamComponent } from '../../src/app/team/login/loginTeam.component';
import { RegisterTeacherComponent } from './registerTeacher/registerTeacher.component';
import { NewClassComponent } from './teacher/newClass/newClass.component';
import { ViewAllClassComponent } from './teacher/viewAllClass/viewAllClass.component';
import { LogsClassComponent } from './teacher/logsClass/logsClass.component';
import { CanvasReviewComponent } from './teacher/logsClass/reviewCanvas/canvasReview.component';
import { GradeCanvasComponent } from './teacher/logsClass/gradeCanvas/gradeCanvas.component';
import { DashboardComponent } from './teacher/dashboard/dashboard.component';
import { LogsBusinessComponent } from './teacher/logsBusiness/logsBusiness.component';

import { DashboardTeamComponent } from '../../src/app/team/dashborad/dashboardTeam.component';
import { LandingQuizComponent } from '../../src/app/team/quiz/landing/landingQuiz.component';
import { QuizComponent } from '../../src/app/team/quiz/quiz/quiz.component';
import { ResultQuizComponent } from '../../src/app/team/quiz/result/resultQuiz.component';
import { AgoraMarketComponent } from '../../src/app/team/agoraMarket/agoraMarket.component';
import { MakeChoiceMarketComponent } from '../../src/app/team/makeChoiceMarket/makeChoiceMarket.component';
import { LandingCanvasComponent } from '../../src/app/team/canvas/landing/landingCanvas.component';
import { CanvasComponent } from '../../src/app/team/canvas/canvas.component';
import { GraduatedComponent } from '../../src/app/team/canvas/graduated/graduated.component';
import { CheckCanvasComponent } from '../../src/app/team/canvas/checkGrade/checkGrade.component';
import { CanvasRenewComponent } from '../../src/app/team/canvas/canvasRenew/canvasRenew.component';
import { GraduatedRenewComponent } from '../../src/app/team/canvas/graduatedRenew/graduatedRenew.component';

// ************** GAME PIZZA **************

import { CostVariablePizzaComponent } from '../../src/app/team/game/pizza/costVariable/costVariablePizza.component';
import { PackingPizzaComponent } from '../../src/app/team/game/pizza/packing/packingPizza.component';
import { AttendeePizzaComponent } from '../../src/app/team/game/pizza/attendee/attendeePizza.component';
import { LocationPizzaComponent } from '../../src/app/team/game/pizza/location/locationPizza.component';
import { PromotionPizzaComponent } from '../../src/app/team/game/pizza/promotion/promotionPizza.component';
import { OfferPizzaComponent } from '../../src/app/team/game/pizza/offer/offerPizza.component';
import { SellPizzaComponent } from '../../src/app/team/game/pizza/sellPizza/sellPizza.component';

// ************** GAME MANUFACTURE **************

import { PackingManufactureComponent } from '../../src/app/team/game/manufacture/packing/packingManufacture.component';
import { AttendeeManufactureComponent } from '../../src/app/team/game/manufacture/attendee/attendeeManufacture.component';
import { PromotionManufactureComponent } from '../../src/app/team/game/manufacture/promotion/promotionManufacture.component';
import { LocationManufactureComponent } from '../../src/app/team/game/manufacture/location/locationManufacture.component';
import { OfferManufactureComponent } from '../../src/app/team/game/manufacture/offer/offerManufacture.component';
import { SellManufactureComponent } from '../../src/app/team/game/manufacture/sellManufacture/sellManufacture.component';

// ************** GAME DELIVERY **************

import { PackingDeliveryComponent } from '../../src/app/team/game/delivery/packing/packingDelivery.component';
import { AttendeeDeliveryComponent } from '../../src/app/team/game/delivery/attendee/attendeeDelivery.component';
import { EquipmentDeliveryComponent } from '../../src/app/team/game/delivery/equipment/equipmentDelivery.component';
import { PromotionDeliveryComponent } from '../../src/app/team/game/delivery/promotion/promotionDelivery.component';
import { LocationDeliveryComponent } from '../../src/app/team/game/delivery/location/locationDelivery.component';
import { OfferDeliveryComponent } from '../../src/app/team/game/delivery/offer/offerDelivery.component';
import { SellDeliveryComponent } from '../../src/app/team/game/delivery/sellDelivery/sellDelivery.component';

// Pipe
import { FormatoFechaPipe } from './project/pipe/formato-fecha.pipe';
import { FormatoFechaHoraPipe } from './project/pipe/formato-fecha-hora.pipe';

// Servicios

import { InvocaService } from './project/services/invoca.service';
import { UsuarioService } from './project/services/usuario.service';
import { UxService } from './project/services/ux.service';
import { TeacherService } from './project/services/teacher.service';
import { TeamService } from './project/services/team.service';

//Primeng
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ColorPickerModule } from 'primeng/colorpicker';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';


@NgModule({
  declarations: [
    AppComponent,
    CabeceraComponent,
    PieComponent,
    ErrorComponent,
    InicioComponent,
    LoginComponent,
    LoginTeamComponent,
    RegisterTeacherComponent,
    NewClassComponent,
    ViewAllClassComponent,
    LogsClassComponent,
    LogsBusinessComponent,
    DashboardComponent,
    DashboardTeamComponent,
    LandingQuizComponent,
    QuizComponent,
    ResultQuizComponent,
    AgoraMarketComponent,
    MakeChoiceMarketComponent,
    LandingCanvasComponent,
    CanvasComponent,
    GraduatedComponent,
    CanvasReviewComponent,
    GradeCanvasComponent,
    CheckCanvasComponent,
    CanvasRenewComponent,
    GraduatedRenewComponent,

    CostVariablePizzaComponent,
    PackingPizzaComponent,
    AttendeePizzaComponent,
    LocationPizzaComponent,
    PromotionPizzaComponent,
    LocationDeliveryComponent,
    OfferPizzaComponent,
    SellPizzaComponent,

    PackingManufactureComponent,
    AttendeeManufactureComponent,
    PromotionManufactureComponent,
    LocationManufactureComponent,
    OfferManufactureComponent,
    SellManufactureComponent,

    PackingDeliveryComponent,
    AttendeeDeliveryComponent,
    EquipmentDeliveryComponent,
    PromotionDeliveryComponent,
    OfferDeliveryComponent,
    SellDeliveryComponent,

    FormatoFechaPipe,
    AlertaComponent,
    CargandoComponent,
    FormatoFechaHoraPipe,

  ],
  entryComponents: [
    AlertaComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxLoadingModule.forRoot({}),
    ReactiveFormsModule,
    NgSelectModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BrowserAnimationsModule,
    NgxChartsModule,
    FullCalendarModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgxNotifierModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    CalendarModule,
    InputSwitchModule,
    ColorPickerModule,
    KeyFilterModule,
    ChartModule,
    ProgressBarModule,
    MultiSelectModule,
    SelectButtonModule,
    CheckboxModule,
    DialogModule
  ],
  providers: [
    CargandoComponent,
    UsuarioService,
    TeacherService,
    TeamService,
    UxService,
    InvocaService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
