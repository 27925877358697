import { Injectable } from '@angular/core';
import { GlobalVar } from '../../../../config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormatoFechaHoraPipe } from '../pipe/formato-fecha-hora.pipe';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private url_base: string;

  constructor(
    private http: HttpClient
  ) {
    this.url_base = GlobalVar.URL_BASE;
  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  login(arg: any): Observable<any> {
    return this.http
      .post(this.url_base + "usuario/login", { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  loginTeam(arg: any): Observable<any> {
    return this.http
      .post(this.url_base + "usuario/loginTeam", { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  register(arg: any): Observable<any> {
    return this.http
      .post(this.url_base + "usuario/register", { arg: JSON.stringify(arg) })
      .pipe(map(data => data))
      .pipe(catchError(UsuarioService.handleError));
  }

  // *************** SECCION MAPEO ***************


  mapReporteMonitoreoCasos(m: any): any[] {
    return m.map(this.toReporteMonitoreoCasos);
  }

  toReporteMonitoreoCasos(r: any): any {

    let m = <any>({
      sucursal: r.suc_nombre,
      seccion: r.sec_seccion,
      accion: (r.accion == 'llamar') ? 'asistencia' : r.accion,
      accionSgte: (r.accionSgte == 'llamar') ? 'asistencia' : (r.accionSgte == null && (r.caso == 'Perdida' || r.caso == 'Perdida retiro')) ? 'Cierre automático' : r.accionSgte,
      caso: (r.caso == 'Perdida') ? 'Pérdida' : (r.caso == 'Perdida retiro') ? 'Pérdida retiro' : r.caso,
      dif: (r.dif != null) ? r.dif : (r.difActual > 120 && r.sec_seccion == 'Lineal de cajas') ? "120 > " : (r.difActual > 180 && r.sec_seccion == 'Non-Food') ? "180 > " : r.difActual,
      difActual: r.difActual,
      boton: r.reg_boton,
      // nombreBtn: r.nombreBtn,
      fechaInicioAccionString: r.fechaInicio,
      fechaTerminoAccionString: (r.fechaTermino == null) ? '-' : r.fechaTermino,

    });
    return m;
  }

}

