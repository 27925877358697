import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-promotion-manufacture',
  templateUrl: './promotionManufacture.component.html',
  styleUrls: ['./promotionManufacture.component.scss']
})
export class PromotionManufactureComponent implements OnInit {

  selectInstagram = false;
  selectFlyer = false;

  valueInstagram: number = 0;
  valueFlyer: number = 0;

  valueTotal: number = 0;

  priceInstagram = 0;
  priceFlyer = 0;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.priceInstagram = 30;
    this.priceFlyer = 10;

    this.getValuesManufacture();

  }

  ngOnInit() {
  }

  getValuesManufacture() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesManufacture(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          if (data.data.promotion_instagram > 0) {
            this.selectInstagram = true;
            this.valueInstagram = (this.selectInstagram) ? this.priceInstagram : 0;
          }

          if (data.data.promotion_flyers > 0) {
            this.selectFlyer = true;
            this.valueFlyer = (this.selectFlyer) ? this.priceFlyer : 0;
          }

          this.valueTotal = this.valueInstagram + this.valueFlyer;

        }
      });

  }

  buyPromotion() {

    this.showCargando();

    try {

      if (this.valueTotal > 0) {

        let param = {
          selectInstagram: (this.selectInstagram) ? 1 : 0,
          selectFlyer: (this.selectFlyer) ? 1 : 0,
          valueTotal: this.valueTotal,
          valueTotalFinish: false,
          idTeam: this.idTeam
        }

        this.teamService.updateCostFijoManufacture(param)
          .subscribe(data => {
            if (data.status == "OK") {
              this.hideCargando();
              localStorage.setItem('valueTotalPromotion', this.valueTotal.toString());
              this.goTo("location");
            } else {
              this.hideCargando();
              this.ux.notificacion("Error, favor intentar nuevamente", 'error');
            }
          });

      } else {
        this.hideCargando();
        this.ux.notificacion('You must choose at least one promoting tool.', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  goTo(page) {

    switch (page) {

      case 'packing':
        this.router.navigateByUrl('/team/game/manufacture/packing');
        break;

      case 'attendee':
        this.router.navigateByUrl('/team/game/manufacture/attendee');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/manufacture/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/manufacture/location');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updatePromotion(tipo) {

    if (tipo == "instagram") {
      this.valueInstagram = (this.selectInstagram) ? this.priceInstagram : 0;
    } else if (tipo == "flyer") {
      this.valueFlyer = (this.selectFlyer) ? this.priceFlyer : 0;
    }

    this.valueTotal = this.valueInstagram + this.valueFlyer;
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
