import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-promotion-pizza',
  templateUrl: './promotionPizza.component.html',
  styleUrls: ['./promotionPizza.component.scss']
})
export class PromotionPizzaComponent implements OnInit {


  selectInstagram = false;
  selectFlyer = false;
  selectTasting = false;

  valueInstagram: number = 0;
  valueFlyer: number = 0;
  valueTasting: number = 0;

  valueTotal: number = 0;

  priceInstagram = 0;
  priceFlyer = 0;
  priceTasting = 0;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.priceInstagram = 30;
    this.priceFlyer = 10;
    this.priceTasting = 1000;

    this.getIngredient();

  }

  ngOnInit() {
  }

  getIngredient() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getIngredientPizza(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          if (data.data.promotion_instagram > 0) {
            this.selectInstagram = true;
            this.valueInstagram = (this.selectInstagram) ? this.priceInstagram : 0;
          }

          if (data.data.promotion_flyers > 0) {
            this.selectFlyer = true;
            this.valueFlyer = (this.selectFlyer) ? this.priceFlyer : 0;
          }

          if (data.data.promotion_tasting > 0) {
            this.selectTasting = true;
            this.valueTasting = (this.selectTasting) ? this.priceTasting : 0;
          }

          this.valueTotal = this.valueInstagram + this.valueFlyer + this.valueTasting;

        }
      });

  }

  buyPromotion() {

    this.showCargando();

    try {

      if (this.valueTotal > 0) {

        let param = {
          selectInstagram: (this.selectInstagram) ? 1 : 0,
          selectFlyer: (this.selectFlyer) ? 1 : 0,
          selectTasting: (this.selectTasting) ? 1 : 0,
          valueTotal: this.valueTotal,
          valueTotalFinish: false,
          idTeam: this.idTeam
        }

        this.teamService.updateCostFijo(param)
          .subscribe(data => {
            if (data.status == "OK") {
              this.hideCargando();
              localStorage.setItem('valueTotalPromotion', this.valueTotal.toString());
              this.goTo("location");
            } else {
              this.hideCargando();
              this.ux.notificacion("Error, favor intentar nuevamente", 'error');
            }
          });

      } else {
        this.hideCargando();
        this.ux.notificacion('You must hire at least one staff.', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  goTo(page) {

    switch (page) {

      case 'ingredient':
        this.router.navigateByUrl('/team/game/pizza/costVariable');
        break;

      case 'packing':
        this.router.navigateByUrl('/team/game/pizza/packing');
        break;

      case 'attendee':
        this.router.navigateByUrl('/team/game/pizza/attendee');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/pizza/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/pizza/location');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updatePromotion(tipo) {

    if (tipo == "instagram") {
      this.valueInstagram = (this.selectInstagram) ? this.priceInstagram : 0;
    } else if (tipo == "flyer") {
      this.valueFlyer = (this.selectFlyer) ? this.priceFlyer : 0;
    } else if (tipo == "tasting") {
      this.valueTasting = (this.selectTasting) ? this.priceTasting : 0;
    }

    this.valueTotal = this.valueInstagram + this.valueFlyer + this.valueTasting;
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
