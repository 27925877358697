import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../app/project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { UsuarioService } from '../project/services/usuario.service';

@Component({
  selector: 'app-register-teacher',
  templateUrl: './registerTeacher.component.html',
  styleUrls: ['./registerTeacher.component.scss']
})
export class RegisterTeacherComponent implements OnInit {


  genero: any;
  name: any;
  surname: any;
  school: any;
  email: any;
  errorMessage = "";

  // Variables componente cargando  

  loading = false;

  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private ux: UxService

  ) { }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/inicioReportes');
    }
  }

  selectGenero() {
    this.genero = (document.getElementById("dropGenero") as HTMLInputElement).value;
  }

  register() {

    this.errorMessage = "";

    this.showCargando();

    let params = {
      genero: this.genero,
      name: this.name,
      surname: this.surname,
      school: this.school,
      email: this.email,
    }

    this.usuarioService.register(params)

      .subscribe(data => {

        if (data.status == "OK") {

          if (data.data == "Insertado") {
            this.ux.notificacion("Successfully registered user.", 'success');
            this.hideCargando();

            setTimeout(() => {
              this.router.navigateByUrl("/login/teacher");
            }, 1500);


          } else {
            this.ux.notificacion(data.data, 'warning');
            this.hideCargando();
          }

        } else {
          this.hideCargando();
          this.ux.notificacion(data.error.message, 'danger');
        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.register: ', err);
      });
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
