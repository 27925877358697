import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorComponent } from './base/error/error.component';

import { InicioComponent } from './inicio/inicio.component';
import { LoginComponent } from './login/login.component';
import { RegisterTeacherComponent } from './registerTeacher/registerTeacher.component';

import { LoginTeamComponent } from '../../src/app/team/login/loginTeam.component';
import { DashboardTeamComponent } from '../../src/app/team/dashborad/dashboardTeam.component';
import { LandingQuizComponent } from '../../src/app/team/quiz/landing/landingQuiz.component';
import { QuizComponent } from '../../src/app/team/quiz/quiz/quiz.component';
import { ResultQuizComponent } from '../../src/app/team/quiz/result/resultQuiz.component';
import { AgoraMarketComponent } from '../../src/app/team/agoraMarket/agoraMarket.component';
import { MakeChoiceMarketComponent } from '../../src/app/team/makeChoiceMarket/makeChoiceMarket.component';
import { LandingCanvasComponent } from '../../src/app/team/canvas/landing/landingCanvas.component';
import { CanvasComponent } from '../../src/app/team/canvas/canvas.component';
import { GraduatedComponent } from '../../src/app/team/canvas/graduated/graduated.component';
import { CheckCanvasComponent } from '../../src/app/team/canvas/checkGrade/checkGrade.component';
import { CanvasRenewComponent } from '../../src/app/team/canvas/canvasRenew/canvasRenew.component';
import { GraduatedRenewComponent } from '../../src/app/team/canvas/graduatedRenew/graduatedRenew.component';

// GAME PIZZA

import { CostVariablePizzaComponent } from '../../src/app/team/game/pizza/costVariable/costVariablePizza.component';
import { PackingPizzaComponent } from '../../src/app/team/game/pizza/packing/packingPizza.component';
import { AttendeePizzaComponent } from '../../src/app/team/game/pizza/attendee/attendeePizza.component';
import { PromotionPizzaComponent } from '../../src/app/team/game/pizza/promotion/promotionPizza.component';
import { LocationPizzaComponent } from '../../src/app/team/game/pizza/location/locationPizza.component';
import { OfferPizzaComponent } from '../../src/app/team/game/pizza/offer/offerPizza.component';
import { SellPizzaComponent } from '../../src/app/team/game/pizza/sellPizza/sellPizza.component';

// GAME MANUFACTURE

import { PackingManufactureComponent } from '../../src/app/team/game/manufacture/packing/packingManufacture.component';
import { AttendeeManufactureComponent } from '../../src/app/team/game/manufacture/attendee/attendeeManufacture.component';
import { PromotionManufactureComponent } from '../../src/app/team/game/manufacture/promotion/promotionManufacture.component';
import { LocationManufactureComponent } from '../../src/app/team/game/manufacture/location/locationManufacture.component';
import { OfferManufactureComponent } from '../../src/app/team/game/manufacture/offer/offerManufacture.component';
import { SellManufactureComponent } from '../../src/app/team/game/manufacture/sellManufacture/sellManufacture.component';

// GAME DELIVERY

import { PackingDeliveryComponent } from '../../src/app/team/game/delivery/packing/packingDelivery.component';
import { AttendeeDeliveryComponent } from '../../src/app/team/game/delivery/attendee/attendeeDelivery.component';
import { EquipmentDeliveryComponent } from '../../src/app/team/game/delivery/equipment/equipmentDelivery.component';
import { PromotionDeliveryComponent } from '../../src/app/team/game/delivery/promotion/promotionDelivery.component';
import { LocationDeliveryComponent } from '../../src/app/team/game/delivery/location/locationDelivery.component';
import { OfferDeliveryComponent } from '../../src/app/team/game/delivery/offer/offerDelivery.component';
import { SellDeliveryComponent } from '../../src/app/team/game/delivery/sellDelivery/sellDelivery.component';

import { NewClassComponent } from './teacher/newClass/newClass.component';
import { ViewAllClassComponent } from './teacher/viewAllClass/viewAllClass.component';
import { LogsClassComponent } from './teacher/logsClass/logsClass.component';
import { CanvasReviewComponent } from './teacher/logsClass/reviewCanvas/canvasReview.component';
import { GradeCanvasComponent } from './teacher/logsClass/gradeCanvas/gradeCanvas.component';
import { LogsBusinessComponent } from './teacher/logsBusiness/logsBusiness.component';
import { DashboardComponent } from './teacher/dashboard/dashboard.component';

const routes: Routes = [
  { path: '', component: InicioComponent },

  // ************** TEACHER **************

  { path: 'login/teacher', component: LoginComponent },
  { path: 'register/teacher', component: RegisterTeacherComponent },
  { path: 'teacher/newClass', component: NewClassComponent },
  { path: 'teacher/viewAllClass', component: ViewAllClassComponent },
  { path: 'teacher/logsClass', component: LogsClassComponent },
  { path: 'teacher/reviewCanvas', component: CanvasReviewComponent },
  { path: 'teacher/gradeCanvas', component: GradeCanvasComponent },
  { path: 'teacher/logsBusiness', component: LogsBusinessComponent },
  { path: 'teacher/dashboard', component: DashboardComponent },

  // ************** TEAM **************

  { path: 'login/team', component: LoginTeamComponent },
  { path: 'team/dashboard', component: DashboardTeamComponent },
  { path: 'team/quiz/landing', component: LandingQuizComponent },
  { path: 'team/quiz/quiz', component: QuizComponent },
  { path: 'team/quiz/result', component: ResultQuizComponent },
  { path: 'team/agoraMarket', component: AgoraMarketComponent },
  { path: 'team/choiceMarket', component: MakeChoiceMarketComponent },
  { path: 'team/canvas/landing', component: LandingCanvasComponent },
  { path: 'team/canvas', component: CanvasComponent },
  { path: 'team/canvas/graduated', component: GraduatedComponent },
  { path: 'team/canvas/checkGrade', component: CheckCanvasComponent },
  { path: 'team/canvas/canvasRenew', component: CanvasRenewComponent },
  { path: 'team/canvas/graduatedRenew', component: GraduatedRenewComponent },


  // ************** GAME PIZZA **************


  { path: 'team/game/pizza/costVariable', component: CostVariablePizzaComponent },
  { path: 'team/game/pizza/packing', component: PackingPizzaComponent },
  { path: 'team/game/pizza/attendee', component: AttendeePizzaComponent },
  { path: 'team/game/pizza/promotion', component: PromotionPizzaComponent },
  { path: 'team/game/pizza/location', component: LocationPizzaComponent },
  { path: 'team/game/pizza/offer', component: OfferPizzaComponent },
  { path: 'team/game/pizza/sellPizza', component: SellPizzaComponent },


  // ************** GAME MANUFACTURE **************


  { path: 'team/game/manufacture/packing', component: PackingManufactureComponent },
  { path: 'team/game/manufacture/attendee', component: AttendeeManufactureComponent },
  { path: 'team/game/manufacture/promotion', component: PromotionManufactureComponent },
  { path: 'team/game/manufacture/location', component: LocationManufactureComponent },
  { path: 'team/game/manufacture/offer', component: OfferManufactureComponent },
  { path: 'team/game/manufacture/sellManufacture', component: SellManufactureComponent },


  // ************** GAME DELIVERY **************


  { path: 'team/game/delivery/packing', component: PackingDeliveryComponent },
  { path: 'team/game/delivery/attendee', component: AttendeeDeliveryComponent },
  { path: 'team/game/delivery/equipment', component: EquipmentDeliveryComponent },
  { path: 'team/game/delivery/promotion', component: PromotionDeliveryComponent },
  { path: 'team/game/delivery/location', component: LocationDeliveryComponent },
  { path: 'team/game/delivery/offer', component: OfferDeliveryComponent },
  { path: 'team/game/delivery/sellDelivery', component: SellDeliveryComponent },


  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
