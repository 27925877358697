import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../app/project/services/usuario.service';
import { UxService } from '../../project/services/ux.service';

@Component({
  selector: 'app-login-team',
  templateUrl: './loginTeam.component.html',
  styleUrls: ['./loginTeam.component.scss']
})

export class LoginTeamComponent implements OnInit {

  user: any;
  pass: any;
  errorMessage = "";

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private ux: UxService

  ) { }

  ngOnInit() {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondo";
    }

  }

  login() {

    this.errorMessage = "";

    this.showCargando();

    if (this.user == "" || this.user == null || this.pass == "" || this.pass == null) {
      this.ux.notificacion('Credentials are not valid.', 'danger');
      this.hideCargando();
    } else {

      let params = {
        user: this.user,
        pass: this.pass,
      }

      this.usuarioService.loginTeam(params)

        .subscribe(dataLogin => {

          if (dataLogin.data.validado == true) {

            this.hideCargando();

            localStorage.setItem('user', "team");
            localStorage.setItem('idTeam', dataLogin.data.team.idTeam);
            localStorage.setItem('userName', dataLogin.data.team.userName);
            localStorage.setItem('nameTeam', dataLogin.data.team.nameTeam);
            localStorage.setItem('classroom', dataLogin.data.team.classroom);

            let dir = dataLogin.data.team.gameProgress.current_phase.split("/")

            switch (dir[3]) {
              case "Quiz":
                this.router.navigateByUrl('team/quiz/landing');
                break;

              case "AgoraMarket":
                this.router.navigateByUrl('team/agoraMarket');
                break;

              case "LandingCanvas":
                this.router.navigateByUrl('team/canvas/landing');
                break;

              case "Graduated":

                this.router.navigateByUrl('team/canvas/graduated');

                break;

              case "GraduatedRenew":

                this.router.navigateByUrl('team/canvas/graduatedRenew');

                break;

              case "Offer":

                if (dataLogin.data.team.gameProgress.game_area_id == 1) {
                  this.router.navigateByUrl('team/game/pizza/offer');
                } else if (dataLogin.data.team.gameProgress.game_area_id == 2) {
                  this.router.navigateByUrl('team/game/manufacture/offer');
                } else if (dataLogin.data.team.gameProgress.game_area_id == 3) {
                  this.router.navigateByUrl('team/game/delivery/offer');
                }
                break;

              case "Restart":

                if (dataLogin.data.team.gameProgress.game_area_id == 1) {
                  this.router.navigateByUrl('team/game/pizza/offer');
                } else if (dataLogin.data.team.gameProgress.game_area_id == 2) {
                  this.router.navigateByUrl('team/game/manufacture/offer');
                } else if (dataLogin.data.team.gameProgress.game_area_id == 3) {
                  this.router.navigateByUrl('team/game/delivery/offer');
                }
                break;

              case "CanvasRenew":

                this.router.navigateByUrl('team/canvas/canvasRenew');

                break;

              default:
                this.router.navigateByUrl('/team/dashboard');
                break;
            }

          } else if (dataLogin.data.validado == false) {

            this.hideCargando();
            this.errorMessage = "Credentials are not valid.";
            this.ux.notificacion('Credentials are not valid.', 'danger');

          }

        }, err => {
          this.hideCargando();
          this.ux.alerta(err);
          console.log('Error al invocar a usuarioService.login: ', err);
        });
    }
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
