import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-equipment-delivery',
  templateUrl: './equipmentDelivery.component.html',
  styleUrls: ['./equipmentDelivery.component.scss']
})
export class EquipmentDeliveryComponent implements OnInit {

  modalRef: BsModalRef;

  selectEquip_1 = false;
  selectEquip_2 = false;
  selectEquip_3 = false;

  valueEquip_1: number = 0;
  valueEquip_2: number = 0;
  valueEquip_3: number = 0;

  cantStandard: number = 0;
  cantCity: number = 0;
  cantProfessional: number = 0;

  valueTotal: number = 0;

  priceEquip_1 = 0;
  priceEquip_2 = 0;
  priceEquip_3 = 0;

  equipment = "";
  seasonSelect = "";

  amountInitial = 0;
  personnelSelected = 0;

  error = false;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  valueTotalAssistant = 0
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.valueTotalAssistant = parseInt(localStorage.getItem('valueTotalAssistant'));

    this.priceEquip_1 = 151;
    this.priceEquip_2 = 191;
    this.priceEquip_3 = 252;

    this.getValuesDelivery();

  }

  ngOnInit() {
  }

  getValuesDelivery() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesDelivery(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          this.seasonSelect = data.data.season;

          let first = (data.data.first_delivery > 0) ? 1 : 0;
          let second = (data.data.second_delivery > 0) ? 1 : 0;
          let three = (data.data.third_delivery > 0) ? 1 : 0;

          this.personnelSelected = first + second + three;

          if (data.data.standard_equipment > 0) {
            this.cantStandard = data.data.standard_equipment;
          }

          if (data.data.city_equipment > 0) {
            this.cantCity = data.data.city_equipment;
          }

          if (data.data.professional_equipment > 0) {
            this.cantProfessional = data.data.professional_equipment;
          }

          this.valueTotal = this.priceEquip_1 * this.cantStandard + this.priceEquip_2 * this.cantCity + this.priceEquip_3 * this.cantProfessional;

          this.valueTotal = parseFloat(parseFloat(this.valueTotal.toString()).toFixed(2));

        }
      });

  }

  buyEquipment() {

    this.showCargando();

    let personnel = this.cantStandard + this.cantCity + this.cantProfessional;

    try {

      if (this.valueTotal > 0) {

        if (!this.error && personnel == this.personnelSelected) {

          let param = {
            standard: this.cantStandard,
            city: this.cantCity,
            professional: this.cantProfessional,
            valueTotal: this.valueTotal + this.valueTotalAssistant,
            valueTotalFinish: false,
            idTeam: this.idTeam
          }

          this.teamService.updateCostFijoDelivery(param)
            .subscribe(data => {
              if (data.status == "OK") {
                this.hideCargando();
                localStorage.setItem('valueTotalEquipment', this.valueTotal.toString());
                this.goTo("promotion");
              } else {
                this.hideCargando();
                this.ux.notificacion("Error, favor intentar nuevamente", 'error');
              }
            });

        } else {
          this.hideCargando();
          this.ux.notificacion("Warning, Remember that you have only hired " + this.personnelSelected + " assistants", 'warning');
        }

      } else {
        this.hideCargando();
        this.ux.notificacion('Debes equipar a todo el personal', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  goTo(page) {

    switch (page) {

      case 'attendee':
        this.router.navigateByUrl('/team/game/delivery/attendee');
        break;

      case 'equipment':
        this.router.navigateByUrl('/team/game/delivery/equipment');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/delivery/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/delivery/location');
        break;

      case 'offer':
        this.router.navigateByUrl('/team/game/delivery/offer');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updateEquipment(tipo) {

    let personnel = this.cantStandard + this.cantCity + this.cantProfessional;

    if (personnel <= this.personnelSelected) {

      this.error = false;

      this.valueTotal = this.priceEquip_1 * this.cantStandard + this.priceEquip_2 * this.cantCity + this.priceEquip_3 * this.cantProfessional;

      this.valueTotal = parseFloat(parseFloat(this.valueTotal.toString()).toFixed(2));

    } else {
      this.error = true;
      this.ux.notificacion("Warning, Remember that you have only hired " + this.personnelSelected + " assistants", 'warning');
    }

  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
