import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeacherService } from '../..//project/services/teacher.service';
import { UxService } from '../../project/services/ux.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-view-all-class',
  templateUrl: './viewAllClass.component.html',
  styleUrls: ['./viewAllClass.component.scss']
})
export class ViewAllClassComponent implements OnInit {

  emailAdd = "";
  emailFinal = "";
  modalRef: BsModalRef;
  accederForm: FormGroup;
  submitted = false;
  listClass = [];
  listAllTeams = [];
  rowGroupMetadata = {};

  row: any;
  correoUser: any;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  @ViewChild('modalSendEmail') modalSendEmail;

  constructor(
    private router: Router,
    private teacherService: TeacherService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    this.correoUser = localStorage.getItem('correo');

    this.getAllClass();

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }
  }

  getAllClass() {

    this.showCargando();

    let params = {
      user: localStorage.getItem('id'),
    }

    this.teacherService.getAllClass(params)

      .subscribe(data => {
        if (data.status == "OK") {

          this.listClass = data.data.class;
          this.listAllTeams = data.data.allTeams;

          this.listAllTeams.forEach(element => {

            let dir = element.current_phase.split("/")
            element.current_phase = dir[3];

          });

          this.hideCargando();

        } else {
          this.ux.notificacion('Error in get all class.', 'danger');
          this.hideCargando();
        }
      });
  }

  createExcel() {

    this.showCargando();

    let data = []

    // Agrega los encabezados de columna
    data.push(['Class', 'Team', 'Username', 'Password', 'Progress']);

    // Agrega los datos

    this.listAllTeams.forEach(element => {
      data.push([element.classe, element.name, element.username, element.password, element.current_phase]);
    });

    this.createAndDownloadExcel(data, "listAllTeams");

    this.ux.notificacion('File created successfully', 'success');
    this.hideCargando();

  }

  sendEmailTeams() {

    this.showCargando();

    if (this.emailFinal == "" || this.emailFinal == null) {
      this.ux.notificacion('You must enter an email.', 'warning');
      this.hideCargando();
    } else {

      let params = {
        correo: this.emailFinal + ", " + this.correoUser,
        user: this.row.username,
        pass: this.row.password
      }

      this.teacherService.sendEmailTeams(params)

        .subscribe(data => {
          if (data.status == "OK") {

            this.ux.notificacion('Mail sent successfully.', 'success');

            this.hideCargando();

          } else {
            this.ux.notificacion('Error in send email.', 'danger');
            this.hideCargando();
          }
        });

      this.closeModalSendEmail();
    }

  }


  //  *********************************** FUNCIONES DE VISTA ***********************************

  addEmail() {

    if (this.emailFinal == '') {
      this.emailFinal = this.emailAdd;
    } else {
      this.emailFinal = this.emailFinal + ", " + this.emailAdd;
    }


    this.emailAdd = "";
  }

  showModalSendEmail(template, rowData) {
    this.row = rowData;
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  closeModalSendEmail() {
    this.emailFinal = "";
    this.row = null;
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.listAllTeams) {
      for (let i = 0; i < this.listAllTeams.length; i++) {
        let rowData = this.listAllTeams[i];
        let classe = rowData.classe;
        if (i == 0) {
          this.rowGroupMetadata[classe] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.listAllTeams[i - 1];
          let previousRowGroup = previousRowData.brand;
          if (classe === previousRowGroup)
            this.rowGroupMetadata[classe].size++;
          else
            this.rowGroupMetadata[classe] = { index: i, size: 1 };
        }
      }
    }
  }

  createAndDownloadExcel(data: any[], fileName: string) {
    // Crea una hoja de cálculo en blanco
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Convierte la hoja de cálculo a un archivo binario
    const binaryArray = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Crea un enlace para descargar el archivo
    const blob = new Blob([binaryArray], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + '.xlsx';

    // Haga clic en el enlace para descargar el archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }
}
