import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../../project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TeamService } from 'src/app/project/services/team.service';
import { SelectItem } from 'primeng/components/common/selectitem';

@Component({
  selector: 'app-graduated',
  templateUrl: './graduated.component.html',
  styleUrls: ['./graduated.component.scss']
})
export class GraduatedComponent implements OnInit {

  modalRef: BsModalRef;
  dropdownGrade: SelectItem[];
  totalFinancing = 0;
  totalFinancingString = "";
  idTeam = 0;
  idAreaSelect = 0;
  totalGrade = 0;
  seasonSelect = "";
  sellPizza = 0;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  constructor(
    private router: Router,
    private ux: UxService,
    private teamService: TeamService,
    private modalService: BsModalService,
  ) {

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.dropdownGrade = [
      { label: 'High', value: 'high' },
      { label: 'Mid', value: 'mid' },
      { label: 'Low', value: 'low' },
    ];

    this.getCanvas();

    this.updateStateDir();
  }

  ngOnInit() {
  }

  getCanvas() {

    this.showCargando();

    let params = {
      idTeam: this.idTeam,
    }

    this.teamService.getCanvas(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.status == "OK") {

          this.hideCargando();

          if (dataCanvas.data != null) {

            this.idAreaSelect = dataCanvas.data.idAreaSelect;

            this.totalFinancing = dataCanvas.data.total_initial_financing;

            this.totalFinancingString = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(this.totalFinancing);

            this.totalGrade = dataCanvas.data.key_partners_grade + dataCanvas.data.key_activities_grade + dataCanvas.data.key_resources_grade +
              dataCanvas.data.cost_structure_grade + dataCanvas.data.channels_grade + dataCanvas.data.value_propositions_grade + dataCanvas.data.customer_relationship_grade +
              dataCanvas.data.customer_segments_grade + dataCanvas.data.revenue_streams_grade;

          }

        } else {
          this.hideCargando();
        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.getCanvas: ', err);
      });

  }

  updateStateDir() {

    let param = {
      tipo: null,
      idTeam: this.idTeam,
      dir: 'App/Game/Phases/Graduated'
    }

    this.teamService.updateStateDir(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
        }
      });

  }

  checkGrade() {

    this.teamService.setIdTeam(this.idTeam);
    this.router.navigateByUrl('/team/canvas/checkGrade');

  }

  startGame(template) {

    if (this.idAreaSelect == 1) {
      if (this.seasonSelect != null && this.seasonSelect != "" && this.sellPizza != null && this.sellPizza > 0) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
      } else {
        this.ux.notificacion("Warning, please enter the season and the number of pizzas you think you can sell.", 'warning');
      }
    } else if (this.idAreaSelect == 2) {

      if (this.seasonSelect != null && this.seasonSelect != "" && this.sellPizza != null && this.sellPizza > 0) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
      } else {
        this.ux.notificacion("Warning, please enter the season and the number of box of pizza you think you can sell.", 'warning');
      }

    } else if (this.idAreaSelect == 3) {

      if (this.seasonSelect != null && this.seasonSelect != "" && this.sellPizza != null && this.sellPizza > 0) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
      } else {
        this.ux.notificacion("Warning, please enter the season and the number of delivery of pizza you think you can sell.", 'warning');
      }

    }

  }

  startGameLetGo() {

    switch (this.idAreaSelect) {
      case 1:
        this.closeModal();
        localStorage.setItem('idArea', this.idAreaSelect.toString());
        localStorage.setItem('showMarket', true.toString());
        this.router.navigateByUrl('team/game/pizza/costVariable');
        break;

      case 2:
        this.closeModal();
        localStorage.setItem('idArea', this.idAreaSelect.toString());
        localStorage.setItem('showMarket', true.toString());
        this.router.navigateByUrl('team/game/manufacture/packing');

        break;
      case 3:
        this.closeModal();
        localStorage.setItem('idArea', this.idAreaSelect.toString());
        localStorage.setItem('showMarket', true.toString());
        this.router.navigateByUrl('team/game/delivery/attendee');

        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  onChangeDropdown($event) {

    this.seasonSelect = $event.value;

    let param = {
      season: $event.value,
      idTeam: this.idTeam
    }

    this.teamService.updateTypeSeason(param)
      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
        }
      });

  }

  onChangeInput($event, game) {

    let param = {
      sellPizza: this.sellPizza,
      idTeam: this.idTeam,
      game: game
    }

    this.teamService.updateSellPizza(param)
      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
        }
      });

  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
