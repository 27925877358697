import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-attendee-manufacture',
  templateUrl: './attendeeManufacture.component.html',
  styleUrls: ['./attendeeManufacture.component.scss']
})
export class AttendeeManufactureComponent implements OnInit {

  modalRef: BsModalRef;

  selectAssitant = false;
  selectTwoAssistant = false;
  selectThreeAssistant = false;

  priceAssistant = 0;
  priceTwoAssistant = 0;
  priceThreeAssistant = 0;

  valueAssitant = 0;
  valueTwoAssistant = 0;
  valueThreeAssistant = 0;

  valueTotal = 0;
  amountInitial = 0;

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.priceAssistant = 800;
    this.priceTwoAssistant = 800;
    this.priceThreeAssistant = 800;

    this.getValuesManufacture();

  }

  ngOnInit() {
  }

  getValuesManufacture() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValuesManufacture(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();

          if (data.data.first_delivery > 0) {
            this.selectAssitant = true;
            this.valueAssitant = (this.selectAssitant) ? this.priceAssistant : 0;
          }

          if (data.data.second_delivery > 0) {
            this.selectTwoAssistant = true;
            this.valueTwoAssistant = (this.selectTwoAssistant) ? this.priceTwoAssistant : 0;
          }

          if (data.data.third_delivery > 0) {
            this.selectThreeAssistant = true;
            this.valueThreeAssistant = (this.selectThreeAssistant) ? this.priceThreeAssistant : 0;
          }

          this.valueTotal = this.valueAssitant + this.valueTwoAssistant + this.valueThreeAssistant;

        }
      });

  }

  buyAssistant() {

    this.showCargando();

    try {

      if (this.valueTotal > 0) {

        let param = {
          selectAssitant: (this.selectAssitant) ? 1 : 0,
          selectTwoAssistant: (this.selectTwoAssistant) ? 1 : 0,
          selectThreeAssistant: (this.selectThreeAssistant) ? 1 : 0,
          valueTotal: this.valueTotal,
          valueTotalFinish: false,
          idTeam: this.idTeam
        }

        this.teamService.updateCostFijoManufacture(param)
          .subscribe(data => {
            if (data.status == "OK") {
              this.hideCargando();
              localStorage.setItem('valueTotalAssistant', this.valueTotal.toString());
              this.goTo("promotion");
            } else {
              this.hideCargando();
              this.ux.notificacion("Error, favor intentar nuevamente", 'error');
            }
          });

      } else {
        this.hideCargando();
        this.ux.notificacion('You must hire at least one staff.', 'warning');
      }
    } catch (error) {
      this.hideCargando();
      this.ux.notificacion(error, 'error');
    }

  }

  goTo(page) {

    switch (page) {

      case 'packing':
        this.router.navigateByUrl('/team/game/manufacture/packing');
        break;

      case 'attendee':
        this.router.navigateByUrl('/team/game/manufacture/attendee');
        break;

      case 'promotion':
        this.router.navigateByUrl('/team/game/manufacture/promotion');
        break;

      case 'location':
        this.router.navigateByUrl('/team/game/manufacture/location');
        break;
    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  updateAttendee(tipo) {

    if (tipo == "first") {
      this.valueAssitant = (this.selectAssitant) ? this.priceAssistant : 0;
    } else if (tipo == "second") {
      this.valueTwoAssistant = (this.selectTwoAssistant) ? this.priceTwoAssistant : 0;
    } else if (tipo == "three") {
      this.valueThreeAssistant = (this.selectThreeAssistant) ? this.priceThreeAssistant : 0;
    }

    this.valueTotal = this.valueAssitant + this.valueTwoAssistant + this.valueThreeAssistant;
  }

  showModalInfo(template) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl', ignoreBackdropClick: true });
  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
