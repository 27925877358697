import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';


@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {

  listAnswers = [];
  listChoice = [];
  answerSelect: any;
  cont = 0;
  idTeam = 0;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
    private ux: UxService
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.getAllAnswers();

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }
  }

  getAllAnswers() {

    this.showCargando();

    let params = {
    }

    this.teamService.getAllAnswers(params)

      .subscribe(data => {
        if (data.status == "OK") {

          this.listAnswers = data.data;

          this.answerSelect = this.listAnswers[this.cont];

          this.answerSelect.resp = false;

          this.answerSelect.answers.forEach(element => {
            element.active = false;
          });

          this.hideCargando();

        }
      });
  }

  selectChoice(item) {

    this.answerSelect.resp = true;

    this.answerSelect.answers.forEach(element => {
      element.active = false;
    });

    item.active = true;

  }

  nextQuestion() {

    if (this.cont < this.listAnswers.length - 1) {

      if (this.answerSelect.resp) {

        if (this.listChoice.indexOf(this.answerSelect) == -1) {
          this.listChoice.push(this.answerSelect);
        }

        this.cont++;
        this.answerSelect = this.listAnswers[this.cont];

        let isResp = false;
        
        this.answerSelect.answers.forEach(element => {
          if (element.active) {
            isResp = true;
          }
        });

        if (!isResp) {
          this.answerSelect.resp = false;
        } else {
          this.answerSelect.resp = true;
        }

      } else {
        this.ux.notificacion('Debe seleccionar una respuesta.', 'warning');
      }

    } else {

      if (this.listChoice.indexOf(this.answerSelect) == -1) {
        this.listChoice.push(this.answerSelect);
      }

      // VALIDA QUIZ

      // console.log(this.listChoice);

      let puntos = 0;

      this.listChoice.forEach(element => {

        element.answers.forEach(element2 => {

          if (element2.active && element2.is_correct == 1) {
            puntos++;
          }

        });

      });

      let param = {
        idTeam: this.idTeam,
        puntos: puntos,
      }

      this.teamService.saveQuiz(param)

        .subscribe(data => {
          if (data.status == "OK") {

            this.teamService.setListChoice(this.listChoice);
            this.router.navigateByUrl('/team/quiz/result');

            this.hideCargando();

          }
        });

    }

  }

  goBack() {

    if (this.cont > 0) {

      this.cont--;

      this.answerSelect = this.listChoice[this.cont];

    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
