import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { getValueFromObject } from 'ngx-bootstrap/typeahead';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { SelectItem } from 'primeng/components/common/selectitem';
import { TeacherService } from 'src/app/project/services/teacher.service';
import { TeamService } from 'src/app/project/services/team.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-offer-delivery',
  templateUrl: './offerDelivery.component.html',
  styleUrls: ['./offerDelivery.component.scss']
})
export class OfferDeliveryComponent implements OnInit {

  modalRef: BsModalRef;
  costFixed = 0;
  costVar = 0;
  cvu = 0;
  sellPrice = 0;
  puntoEquilibrio = 0;
  idTeam = 0;
  porcentajeMarket = 0;
  amountInitial = 0;
  dropdownGrade: SelectItem[];
  season = "";

  // Variables componente cargando  

  loading = false;
  listAllTeams = [];
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private teacherService: TeacherService,
    private ux: UxService,
    private router: Router,
    private teamService: TeamService,
    private modalService: BsModalService,
  ) {

    var classFondo = document.getElementsByClassName("fondoQuiz")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondo";
    }

    this.cvu = 0.9;

    this.dropdownGrade = [
      { label: '100 %', value: 100 },
      { label: '75 %', value: 75 },
      { label: '50 %', value: 50 },
    ];

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.getValueCost();
    this.updateStateDir();

  }

  ngOnInit() {
  }

  getValueCost() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.getValueCost(param)
      .subscribe(data => {
        if (data.status == "OK") {

          this.costFixed = data.data.cost_fijo;
          this.costVar = data.data.cost_var;
          this.season = data.data.season;

          // switch (this.season) {
          //   case "low":
          //     this.cvu = 0.5;
          //     break;

          //   case "mid":
          //     this.cvu = 0.3;
          //     break;

          //   case "hight":
          //     this.cvu = 0.4;
          //     break;
          // }

          this.hideCargando();
        } else {
          this.hideCargando();
          this.ux.notificacion("Error, favor intentar nuevamente", 'error');
        }
      });
  }

  updateStateDir() {

    let param = {
      tipo: null,
      idTeam: this.idTeam,
      dir: 'App/Game/Phases/Offer'
    }

    this.teamService.updateStateDir(param)

      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
        }
      });

  }

  calculateEqui() {

    this.showCargando();

    this.puntoEquilibrio = this.costFixed / (this.sellPrice - this.cvu)

    this.puntoEquilibrio = parseInt(this.puntoEquilibrio.toString());

    this.hideCargando();

  }

  submitOffer(template) {

    if (this.sellPrice > 0) {

      let param = {
        sellPrice: this.sellPrice,
        idTeam: this.idTeam,
        game: "delivery"
      }

      this.teamService.updateSellPizza(param)
        .subscribe(data => {
          if (data.status == "OK") {
            this.hideCargando();
          }
        });

      this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });

    } else {
      this.ux.notificacion("You must enter a selling price.", 'warning');
    }

  }

  updatePorcentageMarket(template) {
    let param = {
      valueTotal: this.porcentajeMarket,
      idTeam: this.idTeam
    }

    this.teamService.updatePorcentageMarket(param)
      .subscribe(data => {
        if (data.status == "OK") {
          this.hideCargando();
          this.calculateStartAmount(template);
        } else {
          this.hideCargando();
          this.ux.notificacion("Error, favor intentar nuevamente", 'error');
        }
      });
  }

  calculateStartAmount(template) {

    this.amountInitial = this.costFixed + this.costVar;
    this.amountInitial = this.amountInitial + (this.amountInitial * 0.3);
    this.amountInitial = parseFloat(parseFloat(this.amountInitial.toString()).toFixed(2));

    this.closeModal();
    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });

  }

  sellDelivery() {
    this.closeModal();
    this.router.navigateByUrl('/team/game/delivery/sellDelivery');
  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
