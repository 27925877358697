export var GameAreaSetting = {

    AGROINDUSTRY: {
        LOW: {
            'market_value': 65520,
            'target_market_price': 5040,
            'average_unit_price': 13,
            'seasonal_tournout_percentage': 50,
            'main_competitor_percentage': 90,
            'main_competitor': 45360,
            'target_age': 35,
            'base_starter_market': 42328,
            'unit_price': 2.03
        },
        MIDDLE: {
            'market_value': 98280,
            'target_market_price': 7560,
            'average_unit_price': 13,
            'seasonal_tournout_percentage': 75,
            'main_competitor_percentage': 90,
            'main_competitor': 68040,
            'target_age': 35,
            'base_starter_market': 42328,
            'unit_price': 2.03
        },
        HIGH: {
            'market_value': 131040,
            'target_market_price': 10080,
            'average_unit_price': 13,
            'seasonal_tournout_percentage': 100,
            'main_competitor_percentage': 90,
            'main_competitor': 90720,
            'target_age': 35,
            'base_starter_market': 42328,
            'unit_price': 2.03
        }
    },

    MANUFACTURING: {

        LOW: {
            'market_value': 7434,
            'target_market_price': 252,
            'average_unit_price': 29.50,
            'seasonal_tournout_percentage': 50,
            'main_competitor_percentage': 50,
            'main_competitor': 252,
            'target_age': 35,
            'base_starter_market': 10522,
            'unit_price': 13.80
        },
        MIDDLE: {
            'market_value': 11151,
            'target_market_price': 378,
            'average_unit_price': 29.50,
            'seasonal_tournout_percentage': 75,
            'main_competitor_percentage': 50,
            'main_competitor': 378,
            'target_age': 35,
            'base_starter_market': 10522,
            'unit_price': 13.80
        },
        HIGH: {
            'market_value': 14868,
            'target_market_price': 504,
            'average_unit_price': 29.50,
            'seasonal_tournout_percentage': 100,
            'main_competitor_percentage': 50,
            'main_competitor': 504,
            'target_age': 35,
            'base_starter_market': 10522,
            'unit_price': 13.80
        }

    },
    DELIVERY: {

        LOW: {
            'market_value': 23520,
            'target_market_price': 11760,
            'average_unit_price': 2,
            'seasonal_tournout_percentage': 50,
            'main_competitor_percentage': 25,
            'main_competitor': 4200,
            'target_age': 35,
            'base_starter_market': 3734,
            'unit_price': 0.9
        },
        MIDDLE: {
            'market_value': 35280,
            'target_market_price': 17640,
            'average_unit_price': 2,
            'seasonal_tournout_percentage': 75,
            'main_competitor_percentage': 25,
            'main_competitor': 6300,
            'target_age': 35,
            'base_starter_market': 3734,
            'unit_price': 0.9
        },
        HIGH: {
            'market_value': 47040,
            'target_market_price': 23520,
            'average_unit_price': 2,
            'seasonal_tournout_percentage': 100,
            'main_competitor_percentage': 25,
            'main_competitor': 8400,
            'target_age': 35,
            'base_starter_market': 3734,
            'unit_price': 0.9
        }

    }

};
