import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeacherService } from '../..//project/services/teacher.service';
import { UxService } from '../../project/services/ux.service';

@Component({
  selector: 'app-dashboard-team',
  templateUrl: './dashboardTeam.component.html',
  styleUrls: ['./dashboardTeam.component.scss']
})
export class DashboardTeamComponent implements OnInit {

  videoEnded = false;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teacherService: TeacherService,
    private ux: UxService
  ) {

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondo";
    }

  }

  startGame() {

  }

  restartVideo() {

    var video = <HTMLVideoElement>document.getElementById('video');

    video.pause();
    video.currentTime = 0;
    video.load();

    this.videoEnded = false;

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  onChangeVideoEnd() {
    this.videoEnded = true;
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
