import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeamService } from 'src/app/project/services/team.service';

@Component({
  selector: 'app-quiz-landing',
  templateUrl: './landingQuiz.component.html',
  styleUrls: ['./landingQuiz.component.scss']
})
export class LandingQuizComponent implements OnInit {

  // Variables componente cargando  

  loading = false;
  idTeam = 0;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private teamService: TeamService,
  ) {

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "fondoQuiz";
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }
  }

  startQuiz() {

    this.showCargando();

    let param = {
      idTeam: this.idTeam
    }

    this.teamService.startQuiz(param)

      .subscribe(data => {
        if (data.status == "OK") {

          this.router.navigateByUrl('/team/quiz/quiz');

          this.hideCargando();

        }
      });

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

}
