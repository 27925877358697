import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TeamService } from 'src/app/project/services/team.service';


@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})
export class CanvasComponent implements OnInit {

  modalRef: BsModalRef;
  config = {};
  disabledBtn = true;
  dataModal;

  key_partners = "";
  key_activities = "";
  key_resources = "";
  cost_structure = "";
  channels = "";
  value_propositions = "";
  customer_relationship = "";
  customer_segments = "";
  revenue_streams = "";
  data = "";
  selectCanvas = "";
  idTeam = 0;
  idTime: any;
  cost_fijo = 0;
  amountInitial = 0;
  utility = 0;


  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ux: UxService,
    private teamService: TeamService,
    private modalService: BsModalService,

  ) {

    var classFondo = document.getElementsByClassName("fondoQuiz")[0];

    if (classFondo != null) {

      if (classFondo.className = "fondoQuiz") {
        classFondo.className = "fondo";
      }
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.getCanvas();

    this.reviewCanvas(true);

  }

  ngOnInit() { }

  getCanvas() {

    this.showCargando();

    let params = {
      idTeam: this.idTeam,
    }

    this.teamService.getCanvas(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.status == "OK") {

          if (dataCanvas.data != null) {
            this.key_partners = dataCanvas.data.key_partners;
            this.key_activities = dataCanvas.data.key_activities;
            this.key_resources = dataCanvas.data.key_resources;
            this.cost_structure = dataCanvas.data.cost_structure;
            this.channels = dataCanvas.data.channels;
            this.value_propositions = dataCanvas.data.value_propositions;
            this.customer_relationship = dataCanvas.data.customer_relationship;
            this.customer_segments = dataCanvas.data.customer_segments;
            this.revenue_streams = dataCanvas.data.revenue_streams;
            this.cost_fijo = dataCanvas.data.cost_fijo;
            this.amountInitial = dataCanvas.data.amountInitial;
            this.utility = dataCanvas.data.utility;
          }

          this.hideCargando();

        } else {
          this.hideCargando();
        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.getCanvas: ', err);
      });

  }

  saveCanvas(template) {

    if (this.key_partners == "" || this.key_activities == "" || this.key_resources == "" ||
      this.channels == "" || this.value_propositions == "" ||
      this.customer_relationship == "" || this.customer_segments == "") {
      this.ux.notificacion('You must complete all the canvas fields.', 'warning');

    } else {

      // this.showCargando();

      let params = {
        idTeam: this.idTeam,
        key_partners: this.key_partners,
        key_activities: this.key_activities,
        key_resources: this.key_resources,
        cost_structure: this.cost_structure,
        channels: this.channels,
        value_propositions: this.value_propositions,
        customer_relationship: this.customer_relationship,
        customer_segments: this.customer_segments,
        revenue_streams: this.revenue_streams
      }

      this.teamService.saveCanvas(params)

        .subscribe(dataCanvas => {

          if (dataCanvas.status == "OK") {

            this.hideCargando();

          }

        }, err => {
          this.hideCargando();
          this.ux.alerta(err);
          console.log('Error al invocar a usuarioService.saveCanvas: ', err);
        }, () => {

          this.idTime = setInterval(() => {

            this.reviewCanvas(false);

          }, 12000);

        });


      this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });

    }

  }

  updateCanvas() {

    let params = {
      idTeam: this.idTeam,
      key_partners: this.key_partners,
      key_activities: this.key_activities,
      key_resources: this.key_resources,
      cost_structure: this.cost_structure,
      channels: this.channels,
      value_propositions: this.value_propositions,
      customer_relationship: this.customer_relationship,
      customer_segments: this.customer_segments,
      revenue_streams: this.revenue_streams
    }

    this.teamService.saveCanvas(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.status == "OK") {

          this.hideCargando();

        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.updateCanvas: ', err);
      });
  }

  reviewCanvas(inicio) {

    let params = {
      idTeam: this.idTeam
    }

    this.teamService.reviewCanvas(params)

      .subscribe(dataCanvas => {

        if (dataCanvas.data.is_passed == 1) {

          this.hideCargando();

          if (!inicio) {
            this.modalRef.hide();
          }

          clearInterval(this.idTime);
          this.router.navigateByUrl('team/canvas/graduated');

        }

      }, err => {
        this.hideCargando();
        this.ux.alerta(err);
        console.log('Error al invocar a usuarioService.reviewCanvas: ', err);
      });

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showModal(template, tipo) {

    switch (tipo) {
      case 'key_partners':
        this.data = this.key_partners;
        this.selectCanvas = 'key_partners';
        break;
      case 'key_activities':
        this.data = this.key_activities;
        this.selectCanvas = 'key_activities';

        break;
      case 'key_resources':
        this.data = this.key_resources;
        this.selectCanvas = 'key_resources';

        break;
      case 'cost_structure':
        this.data = this.cost_structure;
        this.selectCanvas = 'cost_structure';

        break;
      case 'channels':
        this.data = this.channels;
        this.selectCanvas = 'channels';

        break;
      case 'value_propositions':
        this.data = this.value_propositions;
        this.selectCanvas = 'value_propositions';

        break;
      case 'customer_relationship':
        this.data = this.customer_relationship;
        this.selectCanvas = 'customer_relationship';

        break;

      case 'customer_segments':
        this.data = this.customer_segments;
        this.selectCanvas = 'customer_segments';

        break;

      case 'revenue_streams':
        this.data = this.revenue_streams;
        this.selectCanvas = 'revenue_streams';

        break;
    }

    this.dataModal = this.getDataCanvas(tipo);

    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  closeModal() {

    switch (this.selectCanvas) {
      case 'key_partners':
        this.key_partners = this.data;
        break;

      case 'key_activities':
        this.key_activities = this.data;
        break;

      case 'key_resources':
        this.key_resources = this.data;
        break;

      case 'cost_structure':
        this.cost_structure = this.data;
        break;

      case 'channels':
        this.channels = this.data;
        break;

      case 'value_propositions':
        this.value_propositions = this.data;
        break;

      case 'customer_relationship':
        this.customer_relationship = this.data;
        break;

      case 'customer_segments':
        this.customer_segments = this.data;
        break;

      case 'revenue_streams':
        this.revenue_streams = this.data;
        break;

    }

    this.updateCanvas();


    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  getDataCanvas(tipo) {
    let config = {};

    switch (tipo) {
      case 'key_partners':
        config = {
          name: 'Key Partners',
          key: 'key_partners',
          icon: '/images/ico-key-partners.png',
          description: 'Who are our partners in building our Value Proposition?<br>What key resources are we receiving from them? What key activities do our partners develop?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'key_activities':
        config = {
          name: 'Key Activities',
          key: 'key_activities',
          icon: '/images/ico-key-activities.png',
          description: 'What key activities does our value proposition require?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'key_resources':
        config = {
          name: 'Key Resources',
          key: 'key_resources',
          icon: '/images/ico-key-resources.png',
          description: 'What key resources does our value proposition require?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'cost_structure':
        config = {
          name: 'Cost Structure',
          key: 'cost_structure',
          icon: '/images/ico-cost-structure.png',
          description: 'What are the most relevant costs of our Value Proposition?<br>Which are the most expensive key activities?<br>What are the most expensive key resources?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'channels':
        config = {
          name: 'Channels',
          key: 'channels',
          icon: '/images/ico-channels.png',
          description: 'How will we inform groups of interest/stakeholders about our products? How will we deliver our products to our groups of interest/stakeholders?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'value_propositions':
        config = {
          name: 'Value Propositions',
          key: 'value_propositions',
          icon: '/images/ico-value-propositions.png',
          description: 'What needs of the interest groups/stakeholders does the organization satisfy? Why should interest groups/stakeholders choose our organization?<br>What combination of services does the organization offer to each group of interest/stakeholder?',
          grades: [{
            label: 'VERY GOOD',
            value: 2
          }, {
            label: 'GOOD',
            value: 1
          }, {
            label: 'NOT GOOD',
            value: 0.5
          }]
        };

        break;
      case 'customer_relationship':
        config = {
          name: 'Customer Relationship',
          key: 'customer_relationship',
          icon: '/images/ico-customer-relationship.png',
          description: 'What relations have been established with interest groups/stakeholders?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'customer_segments':
        config = {
          name: 'Customer Segments',
          key: 'customer_segments',
          icon: '/images/ico-customer-segments.png',
          description: 'What are the groups of interest/ stakeholders and beneficiaries of our activities?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
      case 'revenue_streams':
        config = {
          name: 'Revenue Streams',
          key: 'revenue_streams',
          icon: '/images/ico-revenue-streams.png',
          description: 'What are the revenue streams?',
          grades: [{
            label: 'VERY GOOD',
            value: 1
          }, {
            label: 'GOOD',
            value: 0.5
          }, {
            label: 'NOT GOOD',
            value: 0
          }]
        };

        break;
    }

    return config;

  }

}
