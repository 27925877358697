import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UxService } from '../../app/project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  accederForm: FormGroup;
  submitted = false;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ux: UxService,

  ) { }

  ngOnInit() {
    this.accederForm = this.formBuilder.group({
      correo: ['', [Validators.required]],
      contrasena: ['', [Validators.required]]
    });

    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('/inicioReportes');
    }
  }

  get f() {
    return this.accederForm.controls;
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
