import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { TeacherService } from 'src/app/project/services/teacher.service';
import { UxService } from 'src/app/project/services/ux.service';

@Component({
  selector: 'app-logs-class',
  templateUrl: './logsClass.component.html',
  styleUrls: ['./logsClass.component.scss']
})
export class LogsClassComponent implements OnInit {

  // Variables componente cargando  

  loading = false;
  listAllTeams = [];
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private teacherService: TeacherService,
    private ux: UxService,
    private router: Router,
  ) {

    localStorage.setItem('idTeamSelect', "0");

    this.getAllTeams();
  }

  ngOnInit() {

  }

  getAllTeams() {

    this.showCargando();

    let params = {
      user: localStorage.getItem('id'),
    }

    this.teacherService.getAllTeams(params)

      .subscribe(data => {
        if (data.status == "OK") {

          this.listAllTeams = data.data;

          this.hideCargando();

        } else {
          this.ux.notificacion('Error in get all teams.', 'danger');
          this.hideCargando();
        }
      });
  }

  reviewCanvas(tipo, idTeamSelect) {

    if (tipo == "complete" || tipo == "graduated") {

      this.teacherService.setIdTeam(idTeamSelect);

      localStorage.setItem('idTeamSelect', idTeamSelect);

      this.router.navigateByUrl('/teacher/reviewCanvas');

    } else {

    }

  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
