import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UxService } from '../../project/services/ux.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { TeamService } from 'src/app/project/services/team.service';


@Component({
  selector: 'app-make-choice-market',
  templateUrl: './makeChoiceMarket.component.html',
  styleUrls: ['./makeChoiceMarket.component.scss']
})
export class MakeChoiceMarketComponent implements OnInit {

  modalRef: BsModalRef;

  manufactureVisited = false;
  deliveryVisited = false;
  pizzaVisited = false;
  config = {};
  disabledBtn = true;
  idTeam = 0;

  // Variables componente cargando  

  loading = false;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;

  constructor(
    private router: Router,
    private ux: UxService,
    private teamService: TeamService,
    private modalService: BsModalService,

  ) {

    var classFondo = document.getElementsByClassName("fondoQuiz")[0];

    if (classFondo != null) {

      if (classFondo.className = "fondoQuiz") {
        classFondo.className = "fondo";
      }
    }

    this.idTeam = parseInt(localStorage.getItem('idTeam'));

    this.manufactureVisited = false;
    this.deliveryVisited = false;
    this.pizzaVisited = false;

  }

  ngOnInit() {
    // if (localStorage.getItem('token')) {
    //   this.router.navigateByUrl('/inicioReportes');
    // }
  }

  choiceMarket(tipo: any) {

    let idTipo = 0;

    this.showCargando();

    let dir = 'App/Game/Phases/LandingCanvas';

    if (tipo == 'pizza') {
      idTipo = 1;
    } else if (tipo == 'manifacture') {
      idTipo = 2;
    } else if (tipo == 'delivery') {
      idTipo = 3;
    }

    let param = {
      tipo: idTipo,
      idTeam: this.idTeam,
      dir: dir
    }

    this.teamService.updateStateDir(param)

      .subscribe(data => {
        if (data.status == "OK") {

          this.hideCargando();
          this.closeModal();
          this.router.navigateByUrl('/team/canvas/landing');

        }

      });

    this.hideCargando();

  }




  //  *********************************** FUNCIONES DE VISTA ***********************************


  showModal(template, tipo) {

    switch (tipo) {
      case 'manufacture':

        this.config = {
          seasonalTurnout: 'assets/images/seasonal-turnout-75.png',
          marketCovered: 50,
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 29.50,
        }

        this.manufactureVisited = true;

        break;

      case 'delivery':

        this.config = {
          seasonalTurnout: 'assets/images/seasonal-turnout-75.png',
          marketCovered: 25,
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 2,
        }

        this.deliveryVisited = true;

        break;

      case 'pizza':
        this.config = {
          seasonalTurnout: 'assets/images/seasonal-turnout-75.png',
          marketCovered: 90,
          targetAge: 'assets/images/target-age.png',
          averagePriceProduct: 13,
        }

        this.pizzaVisited = true;

        break;

    }

    if (this.manufactureVisited && this.deliveryVisited && this.pizzaVisited) {
      this.disabledBtn = false;
    }

    this.modalRef = this.modalService.show(template, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  closeModal() {
    this.modalRef.hide();
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }
}
